import React, { useState, useEffect, Fragment, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import { getStakingsHistoryAction, loadingToggleAction } from "../../store/actions/ManageStakingsAction";

const StakeHistory = (props) => {
  const user = JSON.parse(localStorage.userDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(getStakingsHistoryAction());
  }, []);
  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    };
    const formattedDate = new Date(dateTimeString).toLocaleString("en-US", options);
    return formattedDate;
  };
  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Stakings History</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive recentOrderTable">
                <table className="table verticle-middle table-responsive-md">
                  <thead>
                    <tr>
                      <th scope="col">Pool Name</th>
                      <th scope="col">Duration(days)</th>
                      <th scope="col">Profit</th>
                      <th scope="col">Amount Staked</th>
                      <th scope="col">Partner Tier</th>
                      <th scope="col">Earned</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.stakings.stakings &&
                      props.stakings.stakings.map((staking) => (
                        <tr key={staking.id}>
                          <td>{staking.pool_info.name}</td>
                          <td>{staking.pool_info.duration}</td>
                          <td>{staking.profit_percent ? staking.profit_percent + "%" : staking.profit_percent_range}</td>
                          <td>{staking.amount_staked}</td>
                          <td>{staking.tier}</td>
                          <td>{((staking.amount_staked * staking.profit_percent) / 100).toFixed(2)}</td>
                          <td>{formatDate(staking.created_at)}</td>
                          <td>{formatDate(staking.end_date)}</td>
                          <td>{staking.status}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  stakings: state.stakings,
  isLoading: state.isLoading,
  error: state.error,
});

export default connect(mapStateToProps)(StakeHistory);
