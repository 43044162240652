import React, { useState, useEffect, useRef, Fragment, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import { getTransactionsAction, loadingToggleAction } from "../../store/actions/TransactionsActions";
import { calculateProfitPercentage } from "../../services/PartnersService";

const profitTiers = {
  tiers: [
    {
      tier: 1,
      balance: "0 - 500 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "2.5%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "9%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "20%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "33%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "70%",
        },
      ],
    },
    {
      tier: 2,
      balance: "501 - 2,000 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "2.75%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "9.9%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "22%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "36.3%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "77%",
        },
      ],
    },
    {
      tier: 3,
      balance: "2,001 - 5,000 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "3%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "10.8%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "24%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "39.6%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "84%",
        },
      ],
    },
    {
      tier: 4,
      balance: "5,001 - 10,000 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "3.25%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "11.7%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "26%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "42.9%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "91%",
        },
      ],
    },
    {
      tier: 5,
      balance: "10,001 - 25,000 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "3.5%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "12.6%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "28%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "46.2%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "98%",
        },
      ],
    },
    {
      tier: 6,
      balance: "25,001 - 50,000 USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "3.75%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "13.5%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "30%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "49.5%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "105%",
        },
      ],
    },
    {
      tier: 7,
      balance: "50,000+ USDT",
      plans: [
        {
          pool: "Silver Elite",
          investment_period: 10,
          return_on_investment: "4%",
        },
        {
          pool: "Gold Elite",
          investment_period: 30,
          return_on_investment: "14.4%",
        },
        {
          pool: "Platinum Elite",
          investment_period: 60,
          return_on_investment: "32%",
        },
        {
          pool: "Titanium Elite",
          investment_period: 90,
          return_on_investment: "52.8%",
        },
        {
          pool: "Diamond Elite",
          investment_period: 180,
          return_on_investment: "112%",
        },
      ],
    },
  ],
};
const Transactions = (props) => {
  const user = JSON.parse(localStorage.userDetails);
  const dispatch = useDispatch();
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const inputRef = useRef(null); // Create a ref to the input element
  const handleCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select(); // Select the input's text
      navigator.clipboard
        .writeText(inputRef.current.value) // Copy the selected text to the clipboard
        .then(() => {
          setCopyButtonText("Copied");
        })
        .catch((error) => {
          console.error("Error copying text:", error);
        });
    }
  };
  useEffect(() => {
    dispatch(loadingToggleAction(true));
    dispatch(getTransactionsAction());
  }, []);
  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {profitTiers.tiers.map((tier, index) => (
          <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  Tier {tier.tier} (balance: {tier.balance} )
                </h4>
              </div>
              <div className="card-body">
                <div className="table-responsive recentOrderTable">
                  <table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">Pool</th>
                        <th scope="col">Investment Period</th>
                        <th scope="col">Return On Investment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tier.plans.map((plan, ind) => (
                        <tr key={ind}>
                          <td scope="col">{plan.pool}</td>
                          <td scope="col">{plan.investment_period} Days</td>
                          <td scope="col">{calculateProfitPercentage(tier.tier, plan.investment_period, user.account_type)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  transactions: state.transactions,
  showLoading: state.showLoading,
  error: state.error,
});

export default connect(mapStateToProps)(Transactions);
