import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
//import { Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import { forgotPasswordAction } from "../../store/actions/AuthActions";

import profileIcon from "./../../images/profileIcon.png";
import {
  partnersToggleLoadingAction,
  getLoggedInPartnerAction,
  updateLoggedInPartnerAction,
  sendPartnerEmailVerificationLinkAction,
  uploadPartnerProfilePhotoAction,
  updatePasswordAction,
} from "../../store/actions/PartnersActions";
import { COUNTRIES } from "../countries";
import swal from "sweetalert";
const inputBlog = [
  { label: "Name", value: "" },
  { label: "Specialty", value: "Developer" },
  { label: "Skills", value: "HTML,  JavaScript,  PHP" },
];

const options2 = [
  { value: "1", label: "Select Gender" },
  { value: "2", label: "Male" },
  { value: "3", label: "Female" },
  { value: "4", label: "Other" },
];
const options3 = [
  { value: "1", label: "Russia" },
  { value: "2", label: "Canada" },
  { value: "3", label: "China" },
  { value: "4", label: "India" },
];
const options4 = [
  { value: "1", label: "Krasnodar" },
  { value: "2", label: "Tyumen" },
  { value: "3", label: "Chelyabinsk" },
  { value: "4", label: "Moscow" },
];

const EditProfile = (props) => {
  //const [selectOption , setSelectOption] = useState('Gender');
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.userDetails);
  const navigate = useNavigate();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [profilePhotoModal, setProfilePhotoModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(profileIcon);

  const [partner, setPartner] = useState({});
  const [partnerProfileName, setPartnerProfileName] = useState("");

  const [defaultValueOption, setDefaultValue] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [errors, setErrors] = useState({ password: "", password_mismatch: "" });
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [error, setError] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  //   const partnerObj = {
  //     _id: partner._id,
  //     name: partner.name,
  //     email: partner.email,
  //     role: partner.role,
  //     balance: partner.balance,
  //     total_staked: partner.total_staked,
  //   };
  //   setPartner(partnerObj);

  const openProfilePhotoModal = () => {
    setProfilePhotoModal(true);
  };

  const closeProfilePhotoModal = () => {
    setProfilePhotoModal(false);
  };
  const openresetPasswordModal = () => setResetPasswordModal(true);
  const closeresetPasswordModal = () => setResetPasswordModal(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageUrl(reader.result);
      };

      reader.readAsDataURL(file);
    }

    setSelectedPhoto(file);
  };

  useEffect(() => {
    dispatch(partnersToggleLoadingAction(!props.partners.showLoading));
    dispatch(getLoggedInPartnerAction());
    let user = JSON.parse(localStorage.userDetails);
    setPartner(user);
    setPartnerProfileName(user.name);
    setTimeout(() => {}, 2000);
  }, []);


  const handleSelectGender = (selectedOption) => {
    setPartner((partner) => ({
      ...partner,
      ["gender"]: selectedOption.label,
    }));
  };

  const handleSelectCountry = (selectedOption) => {
    setPartner((partner) => ({
      ...partner,
      ["country"]: selectedOption.label,
    }));
  };
  const handleUpdatePartner = (e) => {
    e.preventDefault();
    dispatch(partnersToggleLoadingAction(!props.partners.showLoading));
    dispatch(updateLoggedInPartnerAction(partner));
    setTimeout(() => {
      setIsLoaded(false);
    }, 2000);
  };
  const handleUpdateProfilePhoto = (e) => {
    e.preventDefault();
    if (!selectedPhoto) return swal("Oops", "Please Select a photo to upload!", "error");
    dispatch(uploadPartnerProfilePhotoAction(partner, selectedPhoto));
    setTimeout(() => {
      setIsLoaded(false);
      setProfilePhotoModal(false);
    }, 2000);
  };

  const handleInputChange = (attr, value) => {
    setPartner((partner) => ({
      ...partner,
      [attr]: value,
    }));
  };
  const handleVerifyEmail = (e) => {
    e.preventDefault();
    dispatch(sendPartnerEmailVerificationLinkAction());
  };
  const closeUpdatePasswordModal = () => {
    setUpdatePasswordModal(false);
    setOldPassword("");
    setNewPassword("");
    setRePassword("");
    setPasswordMismatch(false);
    setError("");
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== rePassword) {
      setPasswordMismatch(true);
      setError("Passwords do not match");
      return;
    }
    setPasswordMismatch(false);
    setError("");
    await dispatch(updatePasswordAction(oldPassword, newPassword, rePassword));
    closeUpdatePasswordModal();
  };
  function handleForgotPassword(e) {
    e.preventDefault();
    dispatch(forgotPasswordAction(user.email , navigate));
  }

  const openUpdatePasswordModal = () => {
    if (user.isPass) {
      setUpdatePasswordModal(true);
    } else {
      openresetPasswordModal(true);
    }
  };
  const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
  const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
  const toggleRePasswordVisibility = () => setShowRePassword(!showRePassword);
  const defaultCountryOption = COUNTRIES.find((country) => country.label === props.partners.partners.country);

  const defaultCountryValue = defaultCountryOption ? defaultCountryOption : null;

  // if (isLoaded === false) {
  //   return <>loading...</>;
  // }

  return (
    <>
      {props.partners.showLoading ? (
        <div className="card profile-card card-bx m-b30">
          <div className="card-header">
            <h6 className="title">Edit Profile</h6>
          </div>

          <div className="card-body">
            <Bars height="25" width="25" color="#9568ff" ariaLabel="bars-loading" wrapperStyle={{}} wrapperClass="" visible={true} />
          </div>
          <div className="card-footer"></div>
        </div>
      ) : (
        <>
          {/* Modal for updating partner profile photo */}
          <Modal className="modal fade" show={profilePhotoModal} onHide={() => closeProfilePhotoModal()}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Profile Photo</h5>
                <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeProfilePhotoModal()}>
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                {/* Form for Pool Creation */}
                <form className="comment-form" onSubmit={(e) => handleUpdateProfilePhoto(e)}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <div className="author-profile">
                          <div className="author-media">
                            <img src={imageUrl} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-6 col-sm-6">
                      <div className="form-group mb-3">
                        <label htmlFor="upload" className="submit btn btn-primary">
                          Upload photo
                        </label>
                        <input type="file" id="upload" onChange={handleFileChange} className="visually-hidden" name="upload" />
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div className="form-group mb-3">
                        <input type="submit" value="Update Photo" className="submit btn btn-primary" name="submit" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
          {/* Modal for updating partner password with normal sign up */}
          <Modal className="modal fade" style={{ zIndex: 19 }} show={updatePasswordModal} onHide={closeUpdatePasswordModal}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">UPDATE PASSWORD</h5>
                <Button variant="" type="button" className="close" data-dismiss="modal" onClick={closeUpdatePasswordModal}>
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <form className="comment-form" onSubmit={handleUpdatePassword}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group mb-3 position-relative">
                        <label htmlFor="oldPassword" className="text-black font-w600">
                          Old Password <span className="required">*</span>
                        </label>
                        <input
                          type={showOldPassword ? "text" : "password"}
                          value={oldPassword}
                          className="form-control"
                          onChange={(e) => setOldPassword(e.target.value)}
                          name="oldPassword"
                          placeholder="Enter Old Password"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-link password-toggle-btn"
                          onClick={toggleOldPasswordVisibility}
                          style={{ position: "absolute", top: "70%", right: "-1px", transform: "translateY(-50%)" }}
                        >
                          {showOldPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3 position-relative">
                        <label htmlFor="newPassword" className="text-black font-w600">
                          New Password <span className="required">*</span>
                        </label>
                        <input
                          type={showNewPassword ? "text" : "password"}
                          value={newPassword}
                          className="form-control"
                          onChange={(e) => setNewPassword(e.target.value)}
                          name="newPassword"
                          placeholder="Enter New Password"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-link password-toggle-btn"
                          onClick={toggleNewPasswordVisibility}
                          style={{ position: "absolute", top: "70%", right: "-1px", transform: "translateY(-50%)" }}
                        >
                          {showNewPassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group mb-3 position-relative">
                        <label htmlFor="rePassword" className="text-black font-w600">
                          Re-enter New Password <span className="required">*</span>
                        </label>
                        <input
                          type={showRePassword ? "text" : "password"}
                          value={rePassword}
                          className="form-control"
                          onChange={(e) => setRePassword(e.target.value)}
                          name="rePassword"
                          placeholder="Re-enter New Password"
                          required
                        />
                        <button
                          type="button"
                          className="btn btn-link password-toggle-btn"
                          onClick={toggleRePasswordVisibility}
                          style={{ position: "absolute", top: "70%", right: "-1px", transform: "translateY(-50%)" }}
                        >
                          {showRePassword ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                        </button>
                      </div>
                    </div>
                    {error && (
                      <div className="col-lg-12">
                        <div className="text-danger mb-3">{error}</div>
                      </div>
                    )}
                    <div className="col-lg-12">
                      <div className="form-group mb-3">
                        <input type="submit" value="Update Password" className="submit btn btn-primary" name="submit" />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
          {/* Modal for updating partner password with Google sign up */}
          <Modal className="modal fade" style={{ zIndex: 19 }} show={resetPasswordModal} onHide={closeresetPasswordModal}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Password</h5>
                <Button variant="" type="button" className="close" data-dismiss="modal" onClick={closeresetPasswordModal}>
                  <span>×</span>
                </Button>
              </div>
              <div className="modal-body">
                <form className="comment-form" onClick={handleForgotPassword}>
                  <div className="form-group">
                    <label className="title">To update the password for the first time please update it with reset password link. </label>
                  </div>
                  <button type="submit" className="btn btn-primary">
                   Reset Password
                  </button>
                </form>
              </div>
            </div>
          </Modal>

          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="clearfix">
                <div className="card card-bx profile-card author-profile m-b30">
                  <div className="card-body">
                    <div className="p-5">
                      <div className="author-profile">
                        <div className="author-media">
                          <img src={user.image_url ? user.image_url : profileIcon} alt="" />
                          <div className="upload-link" onClick={openProfilePhotoModal} title="" data-toggle="tooltip" data-placement="right" data-original-title="update">
                            {/* <input type="file"  onChange={handleFileChange} className="update-flie" /> */}
                            <span>
                              <i className="fa fa-pen"></i>
                            </span>
                          </div>
                        </div>
                        <div className="author-info">
                          <h6 className="title">{partnerProfileName}</h6>
                          <span>Partner</span>
                        </div>
                      </div>
                    </div>
                    <div className="info-list">
                      <ul>
                        <li>
                          <span>Tier</span>
                          <span>{partner.tier}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="info-list">
                    <ul>
                      <li>
                        <span>Referral Level</span>
                        <span>{partner.referral_level}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="card profile-card card-bx m-b30">
                <div className="card-header">
                  <h6 className="title">Edit Profile</h6>
                </div>
                <form className="profile-form" onSubmit={handleUpdatePartner}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Name</label>
                        <input
                          type="text"
                          name="partnerName"
                          className="form-control"
                          onChange={(e) => handleInputChange("name", e.target.value)}
                          defaultValue={partner.name}
                          placeholder="Enter Name"
                        />
                      </div>

                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Email</label>
                        <input type="text" className="form-control" onChange={(e) => handleInputChange("email", e.target.value)} defaultValue={partner.email} placeholder="Your Email" />
                        {!user.is_verified && (
                          <div>
                            <br />
                            <button className="btn btn-primary" onClick={handleVerifyEmail}>
                              {" "}
                              Verify Email
                            </button>
                          </div>
                        )}
                      </div>

                      {/* <div className="col-sm-6 m-b30">
                        <label className="form-label">Gender</label>
                        <Select
                          options={options2}
                          className="custom-react-select"
                          onChange={handleSelectGender}
                          defaultValue={partner.gender === "Male" ? options2[1] : partner.gender === "Female" ? options2[2] : options2[3]}
                          isSearchable={false}
                        />
                      </div> */}
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Phone</label>
                        <input type="text" className="form-control" onChange={(e) => handleInputChange("phone", e.target.value)} defaultValue={partner.phone} placeholder="Your Phone Number" />
                      </div>
                      <div className="col-sm-6 m-b30">
                        <label className="form-label">Country</label>
                        <Select
                          options={COUNTRIES}
                          className="custom-react-select"
                          onChange={handleSelectCountry}
                          defaultValue={defaultCountryValue}
                          isSearchable={true}
                          placeholder={"Select Country"}
                        />
                      </div>
                      {/* <div className="col-sm-6 m-b30">
                                   <label className="form-label">City</label> 
                                   <Select options={options4}  className="custom-react-select" 
                                       defaultValue={options4[0]}
                                       isSearchable={false}
                                   />
                               </div> */}
                    </div>
                  </div>
                  <div className="card-footer">
                    <button className="btn btn-primary">UPDATE</button>
                    <Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={openUpdatePasswordModal}>
                      UPDATE PASSWORD
                    </Button>
                    {/* <Link to={"#"} className="btn-link">
             Forgot your password?
           </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  showLoading: state.partners.showLoading,
  error: state.error,
});
export default connect(mapStateToProps)(EditProfile);
