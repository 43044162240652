import { getProcessedDeposits, formatError, getUnProcessedDeposits, transferUsdt, transferAllUsdt } from "../../services/PartnerDepositsService";
import {
  LOADING_TOGGLE_ACTION,
  CONFIRMED_GET_PROCESSED_DEPOSITS_ACTION,
  CONFIRMED_GET_UNPROCESSED_DEPOSITS_ACTION,
  CONFIRMED_TRANSFER_USDT_ACTION,
  CONFIRMED_TRANSFER_USD_FAILED_ACTION,
} from "./PartnerDepositsTypes";
import swal from "sweetalert";

//Action for loading Toggle
export function loadingAction(_loading) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: _loading,
  };
}
//Action for reading processed Deposits
export function getProcessedDepositsAction() {
  return (dispatch, getState) => {
    getProcessedDeposits()
      .then((response) => {
        let processedDeposits = response.data;
        dispatch(confirmedGetProcessedDepositsAction(processedDeposits));
      })
      .catch((error) => {
        console.log(error);
        // const errorMessage = formatError(error.response.data);
        // dispatch(getTransactionsFailedAction(errorMessage));
      });
  };
}

export function confirmedGetProcessedDepositsAction(_processedDeposits) {
  return {
    type: CONFIRMED_GET_PROCESSED_DEPOSITS_ACTION,
    payload: _processedDeposits,
  };
}

//Action for reading Un-processed Deposits
export function getUnProcessedDepositsAction() {
  return (dispatch, getState) => {
    getUnProcessedDeposits()
      .then((response) => {
        let unProcessedDeposits = response.data;
        dispatch(confirmedGetUnProcessedDepositsAction(unProcessedDeposits));
      })
      .catch((error) => {
        console.log(error);
        // const errorMessage = formatError(error.response.data);
        // dispatch(getTransactionsFailedAction(errorMessage));
      });
  };
}

export function confirmedGetUnProcessedDepositsAction(_unProcessedDeposits) {
  return {
    type: CONFIRMED_GET_UNPROCESSED_DEPOSITS_ACTION,
    payload: _unProcessedDeposits,
  };
}

//Action for Transfering USDT to Platform Wallet
export function transferUsdtAction(_depositDoc) {
  return (dispatch, getState) => {
    transferUsdt(_depositDoc)
      .then((response) => {
        let processedDeposit = response.data;
        dispatch(confirmedTransferUsdtAction(processedDeposit));
      })
      .catch((error) => {
        console.log(error);
        // const errorMessage = formatError(error.response.data);
        dispatch(confirmedTransferUsdtFailedAction(error));
      });
  };
}

//Action for Transfering USDT to Platform Wallet
export function transferAllUsdtAction() {
  return (dispatch, getState) => {
    transferAllUsdt()
      .then((response) => {
        let processedDeposit = response.data;
        dispatch(confirmedTransferUsdtAction(processedDeposit));
        dispatch(loadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        // const errorMessage = formatError(error.response.data);
        dispatch(loadingAction(false));
        dispatch(confirmedTransferUsdtFailedAction(error));
      });
  };
}
export function confirmedTransferUsdtAction(_processedDeposit) {
  return {
    type: CONFIRMED_TRANSFER_USDT_ACTION,
    payload: _processedDeposit,
  };
}

export function confirmedTransferUsdtFailedAction(_error) {
  if (_error.response.data === "No deposits.") {
    swal("Sorry", `There are no new Deposits to process`, "error", { button: "Okay!" });
  } else {
    swal("Oops", `Transfer USDT Failed Please try again in a while`, "error", { button: "Try Again!" });
  }
  return {
    type: CONFIRMED_TRANSFER_USD_FAILED_ACTION,
    payload: _error,
  };
}
