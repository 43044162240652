export const COUNTRIES = [
  { value: "1", label: "Afghanistan", code: "AF", callingCode: "+93" },
  { value: "2", label: "Albania", code: "AL", callingCode: "+355" },
  { value: "3", label: "Algeria", code: "DZ", callingCode: "+213" },
  { value: "4", label: "Andorra", code: "AD", callingCode: "+376" },
  { value: "5", label: "Angola", code: "AO", callingCode: "+244" },
  { value: "6", label: "Antigua and Barbuda", code: "AG", callingCode: "+1" },
  { value: "7", label: "Argentina", code: "AR", callingCode: "+54" },
  { value: "8", label: "Armenia", code: "AM", callingCode: "+374" },
  { value: "9", label: "Australia", code: "AU", callingCode: "+61" },
  { value: "10", label: "Austria", code: "AT", callingCode: "+43" },
  { value: "11", label: "Azerbaijan", code: "AZ", callingCode: "+994" },
  { value: "12", label: "Bahamas", code: "BS", callingCode: "+1" },
  { value: "13", label: "Bahrain", code: "BH", callingCode: "+973" },
  { value: "14", label: "Bangladesh", code: "BD", callingCode: "+880" },
  { value: "15", label: "Barbados", code: "BB", callingCode: "+1" },
  { value: "16", label: "Belarus", code: "BY", callingCode: "+375" },
  { value: "17", label: "Belgium", code: "BE", callingCode: "+32" },
  { value: "18", label: "Belize", code: "BZ", callingCode: "+501" },
  { value: "19", label: "Benin", code: "BJ", callingCode: "+229" },
  { value: "20", label: "Bhutan", code: "BT", callingCode: "+975" },
  { value: "21", label: "Bolivia", code: "BO", callingCode: "+591" },
  { value: "22", label: "Bosnia and Herzegovina", code: "BA", callingCode: "+387" },
  { value: "23", label: "Botswana", code: "BW", callingCode: "+267" },
  { value: "24", label: "Brazil", code: "BR", callingCode: "+55" },
  { value: "25", label: "Brunei", code: "BN", callingCode: "+673" },
  { value: "26", label: "Bulgaria", code: "BG", callingCode: "+359" },
  { value: "27", label: "Burkina Faso", code: "BF", callingCode: "+226" },
  { value: "28", label: "Burundi", code: "BI", callingCode: "+257" },
  { value: "29", label: "Cabo Verde", code: "CV", callingCode: "+238" },
  { value: "30", label: "Cambodia", code: "KH", callingCode: "+855" },
  { value: "31", label: "Cameroon", code: "CM", callingCode: "+237" },
  { value: "32", label: "Canada", code: "CA", callingCode: "+1" },
  { value: "33", label: "Central African Republic", code: "CF", callingCode: "+236" },
  { value: "34", label: "Chad", code: "TD", callingCode: "+235" },
  { value: "35", label: "Chile", code: "CL", callingCode: "+56" },
  { value: "36", label: "China", code: "CN", callingCode: "+86" },
  { value: "37", label: "Colombia", code: "CO", callingCode: "+57" },
  { value: "38", label: "Comoros", code: "KM", callingCode: "+269" },
  { value: "39", label: "Congo", code: "CG", callingCode: "+242" },
  { value: "40", label: "Costa Rica", code: "CR", callingCode: "+506" },
  { value: "41", label: "Croatia", code: "HR", callingCode: "+385" },
  { value: "42", label: "Cuba", code: "CU", callingCode: "+53" },
  { value: "43", label: "Cyprus", code: "CY", callingCode: "+357" },
  { value: "44", label: "Czechia", code: "CZ", callingCode: "+420" },
  { value: "45", label: "Denmark", code: "DK", callingCode: "+45" },
  { value: "46", label: "Djibouti", code: "DJ", callingCode: "+253" },
  { value: "47", label: "Dominica", code: "DM", callingCode: "+1" },
  { value: "48", label: "Dominican Republic", code: "DO", callingCode: "+1" },
  { value: "49", label: "East Timor", code: "TL", callingCode: "+670" },
  { value: "50", label: "Ecuador", code: "EC", callingCode: "+593" },
  { value: "51", label: "Egypt", code: "EG", callingCode: "+20" },
  { value: "52", label: "El Salvador", code: "SV", callingCode: "+503" },
  { value: "53", label: "Equatorial Guinea", code: "GQ", callingCode: "+240" },
  { value: "54", label: "Eritrea", code: "ER", callingCode: "+291" },
  { value: "55", label: "Estonia", code: "EE", callingCode: "+372" },
  { value: "56", label: "Eswatini", code: "SZ", callingCode: "+268" },
  { value: "57", label: "Ethiopia", code: "ET", callingCode: "+251" },
  { value: "58", label: "Fiji", code: "FJ", callingCode: "+679" },
  { value: "59", label: "Finland", code: "FI", callingCode: "+358" },
  { value: "60", label: "France", code: "FR", callingCode: "+33" },
  { value: "61", label: "Gabon", code: "GA", callingCode: "+241" },
  { value: "62", label: "Gambia", code: "GM", callingCode: "+220" },
  { value: "63", label: "Georgia", code: "GE", callingCode: "+995" },
  { value: "64", label: "Germany", code: "DE", callingCode: "+49" },
  { value: "65", label: "Ghana", code: "GH", callingCode: "+233" },
  { value: "66", label: "Greece", code: "GR", callingCode: "+30" },
  { value: "67", label: "Grenada", code: "GD", callingCode: "+1" },
  { value: "68", label: "Guatemala", code: "GT", callingCode: "+502" },
  { value: "69", label: "Guinea", code: "GN", callingCode: "+224" },
  { value: "70", label: "Guinea-Bissau", code: "GW", callingCode: "+245" },
  { value: "71", label: "Guyana", code: "GY", callingCode: "+592" },
  { value: "72", label: "Haiti", code: "HT", callingCode: "+509" },
  { value: "73", label: "Honduras", code: "HN", callingCode: "+504" },
  { value: "74", label: "Hungary", code: "HU", callingCode: "+36" },
  { value: "75", label: "Iceland", code: "IS", callingCode: "+354" },
  { value: "76", label: "India", code: "IN", callingCode: "+91" },
  { value: "77", label: "Indonesia", code: "ID", callingCode: "+62" },
  { value: "78", label: "Iran", code: "IR", callingCode: "+98" },
  { value: "79", label: "Iraq", code: "IQ", callingCode: "+964" },
  { value: "80", label: "Ireland", code: "IE", callingCode: "+353" },
  { value: "81", label: "Italy", code: "IT", callingCode: "+39" },
  { value: "82", label: "Jamaica", code: "JM", callingCode: "+1" },
  { value: "83", label: "Japan", code: "JP", callingCode: "+81" },
  { value: "84", label: "Jordan", code: "JO", callingCode: "+962" },
  { value: "85", label: "Kazakhstan", code: "KZ", callingCode: "+7" },
  { value: "86", label: "Kenya", code: "KE", callingCode: "+254" },
  { value: "87", label: "Kiribati", code: "KI", callingCode: "+686" },
  { value: "88", label: "Korea, North", code: "KP", callingCode: "+850" },
  { value: "89", label: "Korea, South", code: "KR", callingCode: "+82" },
  { value: "90", label: "Kuwait", code: "KW", callingCode: "+965" },
  { value: "91", label: "Kyrgyzstan", code: "KG", callingCode: "+996" },
  { value: "92", label: "Laos", code: "LA", callingCode: "+856" },
  { value: "93", label: "Latvia", code: "LV", callingCode: "+371" },
  { value: "94", label: "Lebanon", code: "LB", callingCode: "+961" },
  { value: "95", label: "Lesotho", code: "LS", callingCode: "+266" },
  { value: "96", label: "Liberia", code: "LR", callingCode: "+231" },
  { value: "97", label: "Libya", code: "LY", callingCode: "+218" },
  { value: "98", label: "Liechtenstein", code: "LI", callingCode: "+423" },
  { value: "99", label: "Lithuania", code: "LT", callingCode: "+370" },
  { value: "100", label: "Luxembourg", code: "LU", callingCode: "+352" },
  { value: "101", label: "Madagascar", code: "MG", callingCode: "+261" },
  { value: "102", label: "Malawi", code: "MW", callingCode: "+265" },
  { value: "103", label: "Malaysia", code: "MY", callingCode: "+60" },
  { value: "104", label: "Maldives", code: "MV", callingCode: "+960" },
  { value: "105", label: "Mali", code: "ML", callingCode: "+223" },
  { value: "106", label: "Malta", code: "MT", callingCode: "+356" },
  { value: "107", label: "Marshall Islands", code: "MH", callingCode: "+692" },
  { value: "108", label: "Mauritania", code: "MR", callingCode: "+222" },
  { value: "109", label: "Mauritius", code: "MU", callingCode: "+230" },
  { value: "110", label: "Mexico", code: "MX", callingCode: "+52" },
  { value: "111", label: "Micronesia", code: "FM", callingCode: "+691" },
  { value: "112", label: "Moldova", code: "MD", callingCode: "+373" },
  { value: "113", label: "Monaco", code: "MC", callingCode: "+377" },
  { value: "114", label: "Mongolia", code: "MN", callingCode: "+976" },
  { value: "115", label: "Montenegro", code: "ME", callingCode: "+382" },
  { value: "116", label: "Morocco", code: "MA", callingCode: "+212" },
  { value: "117", label: "Mozambique", code: "MZ", callingCode: "+258" },
  { value: "118", label: "Myanmar", code: "MM", callingCode: "+95" },
  { value: "119", label: "Namibia", code: "NA", callingCode: "+264" },
  { value: "120", label: "Nauru", code: "NR", callingCode: "+674" },
  { value: "121", label: "Nepal", code: "NP", callingCode: "+977" },
  { value: "122", label: "Netherlands", code: "NL", callingCode: "+31" },
  { value: "123", label: "New Zealand", code: "NZ", callingCode: "+64" },
  { value: "124", label: "Nicaragua", code: "NI", callingCode: "+505" },
  { value: "125", label: "Niger", code: "NE", callingCode: "+227" },
  { value: "126", label: "Nigeria", code: "NG", callingCode: "+234" },
  { value: "127", label: "North Macedonia", code: "MK", callingCode: "+389" },
  { value: "128", label: "Norway", code: "NO", callingCode: "+47" },
  { value: "129", label: "Oman", code: "OM", callingCode: "+968" },
  { value: "130", label: "Pakistan", code: "PK", callingCode: "+92" },
  { value: "131", label: "Palau", code: "PW", callingCode: "+680" },
  { value: "132", label: "Panama", code: "PA", callingCode: "+507" },
  { value: "133", label: "Papua New Guinea", code: "PG", callingCode: "+675" },
  { value: "134", label: "Paraguay", code: "PY", callingCode: "+595" },
  { value: "135", label: "Peru", code: "PE", callingCode: "+51" },
  { value: "136", label: "Philippines", code: "PH", callingCode: "+63" },
  { value: "137", label: "Poland", code: "PL", callingCode: "+48" },
  { value: "138", label: "Portugal", code: "PT", callingCode: "+351" },
  { value: "139", label: "Qatar", code: "QA", callingCode: "+974" },
  { value: "140", label: "Romania", code: "RO", callingCode: "+40" },
  { value: "141", label: "Russia", code: "RU", callingCode: "+7" },
  { value: "142", label: "Rwanda", code: "RW", callingCode: "+250" },
  { value: "143", label: "Saint Kitts and Nevis", code: "KN", callingCode: "+1" },
  { value: "144", label: "Saint Lucia", code: "LC", callingCode: "+1" },
  { value: "145", label: "Saint Vincent and the Grenadines", code: "VC", callingCode: "+1" },
  { value: "146", label: "Samoa", code: "WS", callingCode: "+685" },
  { value: "147", label: "San Marino", code: "SM", callingCode: "+378" },
  { value: "148", label: "Sao Tome and Principe", code: "ST", callingCode: "+239" },
  { value: "149", label: "Saudi Arabia", code: "SA", callingCode: "+966" },
  { value: "150", label: "Senegal", code: "SN", callingCode: "+221" },
  { value: "151", label: "Serbia", code: "RS", callingCode: "+381" },
  { value: "152", label: "Seychelles", code: "SC", callingCode: "+248" },
  { value: "153", label: "Sierra Leone", code: "SL", callingCode: "+232" },
  { value: "154", label: "Singapore", code: "SG", callingCode: "+65" },
  { value: "155", label: "Slovakia", code: "SK", callingCode: "+421" },
  { value: "156", label: "Slovenia", code: "SI", callingCode: "+386" },
  { value: "157", label: "Solomon Islands", code: "SB", callingCode: "+677" },
  { value: "158", label: "Somalia", code: "SO", callingCode: "+252" },
  { value: "159", label: "South Africa", code: "ZA", callingCode: "+27" },
  { value: "160", label: "South Sudan", code: "SS", callingCode: "+211" },
  { value: "161", label: "Spain", code: "ES", callingCode: "+34" },
  { value: "162", label: "Sri Lanka", code: "LK", callingCode: "+94" },
  { value: "163", label: "Sudan", code: "SD", callingCode: "+249" },
  { value: "164", label: "Suriname", code: "SR", callingCode: "+597" },
  { value: "165", label: "Sweden", code: "SE", callingCode: "+46" },
  { value: "166", label: "Switzerland", code: "CH", callingCode: "+41" },
  { value: "167", label: "Syria", code: "SY", callingCode: "+963" },
  { value: "168", label: "Taiwan", code: "TW", callingCode: "+886" },
  { value: "169", label: "Tajikistan", code: "TJ", callingCode: "+992" },
  { value: "170", label: "Tanzania", code: "TZ", callingCode: "+255" },
  { value: "171", label: "Thailand", code: "TH", callingCode: "+66" },
  { value: "172", label: "Togo", code: "TG", callingCode: "+228" },
  { value: "173", label: "Tonga", code: "TO", callingCode: "+676" },
  { value: "174", label: "Trinidad and Tobago", code: "TT", callingCode: "+1" },
  { value: "175", label: "Tunisia", code: "TN", callingCode: "+216" },
  { value: "176", label: "Turkey", code: "TR", callingCode: "+90" },
  { value: "177", label: "Turkmenistan", code: "TM", callingCode: "+993" },
  { value: "178", label: "Tuvalu", code: "TV", callingCode: "+688" },
  { value: "179", label: "Uganda", code: "UG", callingCode: "+256" },
  { value: "180", label: "United Arab Emirates", code: "AE", callingCode: "+971" },
  { value: "181", label: "United Kingdom", code: "GB", callingCode: "+44" },
  { value: "182", label: "United States", code: "US", callingCode: "+1" },
  { value: "183", label: "Uruguay", code: "UY", callingCode: "+598" },
  { value: "184", label: "Uzbekistan", code: "UZ", callingCode: "+998" },
  { value: "185", label: "Vanuatu", code: "VU", callingCode: "+678" },
  { value: "186", label: "Vatican City", code: "VA", callingCode: "+39" },
  { value: "187", label: "Venezuela", code: "VE", callingCode: "+58" },
  { value: "188", label: "Vietnam", code: "VN", callingCode: "+84" },
  { value: "189", label: "Yemen", code: "YE", callingCode: "+967" },
  { value: "190", label: "Zambia", code: "ZM", callingCode: "+260" },
  { value: "191", label: "Zimbabwe", code: "ZW", callingCode: "+263" },
];
