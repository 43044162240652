import {
  sendVerificationCode,
  getPartners,
  getPartnersWithPagination,
  partnerSearch,
  partnerSearchByPhone,
  partnerSearchByEmail,
  updateVerifyStatus,
  updatePartner,
  updateLoggedInPartner,
  blockPartner,
  getLoggedInParnter,
  getUpdatedBalancePartner,
  formatError,
  getReferredPartners,
  withdrawLoggedInPartner,
  sendEmailVerificationLink,
  uploadPartnerProfilePhoto,
  updatePasswordService,
  sendMessage,
} from "../../services/PartnersService";
import {
  CONFIRMED_GET_PARTNERS_ACTION,
  CONFIRMED_GET_PARTNER_BY_PHONE_ACTION,
  CONFIRMED_GET_PARTNER_BY_EMAIL_ACTION,
  CONFIRMED_GET_PARTNERS_WITH_PAGINATION_ACTION,
  FAILED_GET_PARTNERS_ACTION,
  CONFIRMED_UPDATE_PARTNER_ACTION,
  PARTNERS_NOT_FOUND_ACTION,
  CONFIRMED_GET_REFERRED_PARTNERS_ACTION,
  PARTNERS_TOGGLE_LOADING_ACTION,
} from "./PartnersTypes";
import swal from "sweetalert";

//Action for sending Verfication Code
export function sendVerificationCodeAction() {
  return (dispatch, getState) => {
    sendVerificationCode()
      .then((response) => {
        // localStorage.setItem("userDetails", JSON.stringify(response.data));
        // dispatch(confirmedGetPartnersAction(response.data));
        // console.log("verification Code sent");
      })
      .catch((error) => {
        console.log(error);
        // dispatch(getPartnersFailedAction(error.response.data));
      });
  };
}

//Action for reading Logged In partner
export function getLoggedInPartnerAction() {
  return (dispatch, getState) => {
    getLoggedInParnter()
      .then((response) => {
        localStorage.setItem("userDetails", JSON.stringify(response.data));
        dispatch(confirmedGetPartnersAction(response.data));
        dispatch(partnersToggleLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);

        dispatch(getPartnersFailedAction(error.response.data));
      });
  };
}

//Action for reading Updated Balance of Logged In partner
export function getUpdatedBalancePartnerAction() {
  return (dispatch, getState) => {
    getUpdatedBalancePartner()
      .then((response) => {
        localStorage.setItem("userDetails", JSON.stringify(response.data));
        dispatch(confirmedGetPartnersAction(response.data));
      })
      .catch((error) => {
        console.log(error);

        dispatch(getPartnersFailedAction(error.response.data));
      });
  };
}
//Action for reading all the partners
export function getPartnersAction() {
  return (dispatch, getState) => {
    getPartners()
      .then((response) => {
        dispatch(confirmedGetPartnersAction(response.data));
      })
      .catch((error) => {
        console.log(error);

        dispatch(getPartnersFailedAction(error.response.data));
      });
  };
}

export function confirmedGetPartnersAction(partners) {
  return {
    type: CONFIRMED_GET_PARTNERS_ACTION,
    payload: partners,
  };
}

//Action for reading all the partners with pagination
export function getPartnersWithPaginationAction(_pageNum) {
  return (dispatch, getState) => {
    getPartnersWithPagination(_pageNum)
      .then((response) => {
        dispatch(confirmedGetPartnersWithPaginationAction(response.data));
      })
      .catch((error) => {
        console.log(error);

        dispatch(getPartnersFailedAction(error.response.data));
      });
  };
}

export function confirmedGetPartnersWithPaginationAction(partners) {
  return {
    type: CONFIRMED_GET_PARTNERS_WITH_PAGINATION_ACTION,
    payload: partners,
  };
}

//Action for reading all referred parnters of a Logged In partner
export function getReferredPartnersAction() {
  return (dispatch, getState) => {
    getReferredPartners()
      .then((response) => {
        dispatch(confirmedGetReferredPartnersAction(response.data));
      })
      .catch((error) => {
        console.log(error);

        dispatch(getPartnersFailedAction(error.response.data));
      });
  };
}

export function confirmedGetReferredPartnersAction(_referredPartners) {
  return {
    type: CONFIRMED_GET_REFERRED_PARTNERS_ACTION,
    payload: _referredPartners,
  };
}

//Action for Searching Partners based on filters applied admin only
export function partnerSearchAction(_dateRange, _country, _balanceFrom, _balanceTo, _stakeFrom, _stakeTo, _isReferred) {
  return (dispatch, getState) => {
    partnerSearch(_dateRange, _country, _balanceFrom, _balanceTo, _stakeFrom, _stakeTo, _isReferred)
      .then((response) => {
        dispatch(confirmedGetPartnersAction(response.data));
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = formatError(error.response.data);
        dispatch(notFoundPartners(errorMessage));
      });
  };
}
export function notFoundPartners(error) {
  swal("Oops", "No Partner Found", "error");
  return {
    type: PARTNERS_NOT_FOUND_ACTION,
    payload: error,
  };
}

export function partnersToggleLoadingAction(state) {
  return {
    type: PARTNERS_TOGGLE_LOADING_ACTION,
    payload: state,
  };
}
//Action for Searching Partners by their phone number
export function partnerSearchByPhoneAction(_partnerPhone) {
  return (dispatch, getState) => {
    partnerSearchByPhone(_partnerPhone)
      .then((response) => {
        dispatch(confirmedGetPartnerByPhoneAction(response.data));
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = formatError(error.response.data);
        dispatch(notFoundPartners(errorMessage));
      });
  };
}

export function confirmedGetPartnerByPhoneAction(partner) {
  return {
    type: CONFIRMED_GET_PARTNER_BY_PHONE_ACTION,
    payload: partner,
  };
}
//Action for Searching Partners by their phone number
export function partnerSearchByEmailAction(_partnerEmail) {
  return (dispatch, getState) => {
    partnerSearchByEmail(_partnerEmail)
      .then((response) => {
        dispatch(confirmedGetPartnerByEmailAction(response.data));
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = formatError(error.response.data);
        dispatch(notFoundPartners(errorMessage));
      });
  };
}

export function confirmedGetPartnerByEmailAction(partner) {
  return {
    type: CONFIRMED_GET_PARTNER_BY_EMAIL_ACTION,
    payload: partner,
  };
}

//Action for updating a Partner for Admins
export function updatePartnerAction(_partnerObj) {
  return (dispatch, getState) => {
    updatePartner(_partnerObj)
      .then((response) => {
        dispatch(confirmedUpdatePartnerAction(response.data));
        swal("Great!", "Partner's Profile data is updated successfully", "success");
      })
      .catch((error) => {
        console.log(error);
        dispatch(getPartnersFailedAction(error.response.data));
      });
  };
}

//Action for Updating Logged In partner
export function updateLoggedInPartnerAction(_partnerObj) {
  return (dispatch, getState) => {
    updateLoggedInPartner(_partnerObj)
      .then((response) => {
        localStorage.setItem("userDetails", JSON.stringify(response.data));
        dispatch(confirmedGetUpdatedPartnerAction(response.data));
        dispatch(partnersToggleLoadingAction(false));
      })
      .catch((error) => {
        if (error.response.data === '"email" must be a valid email') swal("Oops", "You have entered an invalid Email address.!", "error");

        console.log(error);
        dispatch(updatePartnerFailedAction(error.response.data));
      });
  };
}

export function confirmedGetUpdatedPartnerAction(partners) {
  swal("Great!", "Your profile data is updated!", "success");
  return {
    type: CONFIRMED_GET_PARTNERS_ACTION,
    payload: partners,
  };
}

//Action for Updating Logged In partner
export function uploadPartnerProfilePhotoAction(_partnerObj, _photo) {
  return (dispatch, getState) => {
    uploadPartnerProfilePhoto(_partnerObj, _photo)
      .then((response) => {
        localStorage.setItem("userDetails", JSON.stringify(response.data));
        dispatch(confirmedUploadPartnerProfilePhotoAction(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updatePartnerFailedAction(error.response.data));
      });
  };
}

export function confirmedUploadPartnerProfilePhotoAction(partners) {
  swal("Great!", "Your profile photo is updated!", "success");
  return {
    type: CONFIRMED_GET_PARTNERS_ACTION,
    payload: partners,
  };
}

//Action for sending partner Email verification
export function sendPartnerEmailVerificationLinkAction() {
  return (dispatch, getState) => {
    sendEmailVerificationLink()
      .then((response) => {
        swal("Thankyou", "Verification email has been sent. Please check your email inbox for email verification. If you dont see any email, please check your spam also.!", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

//Action for hadling withdraws Logged In partner
export function withdrawPartnerAction(_withdrawAmount, _walletAddress, _withdrawCode) {
  return (dispatch, getState) => {
    withdrawLoggedInPartner(_withdrawAmount, _walletAddress, _withdrawCode)
      .then((response) => {
        localStorage.setItem("userDetails", JSON.stringify(response.data));
        dispatch(confirmedWithdrawPartnerAction(response.data, _withdrawAmount, _walletAddress));
      })
      .catch((error) => {
        console.log(error);
        dispatch(updatePartnerFailedAction(error.response.data));
      });
  };
}

export function confirmedWithdrawPartnerAction(_partner, _withdrawAmount, _walletAddress) {
  swal("Great!", `Your request for withdraw of  ${_withdrawAmount} USDT is submitted. Withdraw requests can take up to 24 hours to process.`, "success");
  return {
    type: CONFIRMED_GET_PARTNERS_ACTION,
    payload: _partner,
  };
}

export function updatePartnerFailedAction(error) {
  if (error === "User already registed with the email ID.") swal("Oops", "Email Already Registered!", "error");
  if (error === "User already registed with the wallet address provided.") swal("Oops", "Wallet Address Already Registered. Please choose another one!", "error");
  if (error === "INVALID_WALLET_ADDRESS") swal("Oops", "Please provide valid TRON TRC20 wallet address.", "error");
  if (error === "INVALID_WITHDRAW_CODE") swal("Oops", "You entered Invalid withdraw Code.", "error");

  return {
    type: FAILED_GET_PARTNERS_ACTION,
    payload: error,
  };
}

//Action for Blocking a Partner
export function blockPartnerAction(_partnerId, _status) {
  return (dispatch, getState) => {
    blockPartner(_partnerId, _status)
      .then((response) => {
        dispatch(confirmedUpdatePartnerAction(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getPartnersFailedAction(error.response.data));
      });
  };
}

//Action for Updating Partner verify status
export function updatePartnerVerifyStatusAction(_partnerId, _status) {
  return (dispatch, getState) => {
    updateVerifyStatus(_partnerId, _status)
      .then((response) => {
        dispatch(confirmedUpdatePartnerAction(response.data));
      })
      .catch((error) => {
        console.log(error);
        dispatch(getPartnersFailedAction(error.response.data));
      });
  };
}

//Action for Send Massage from the Contact us
export function sendMessageAction(messageObj) {
  return (dispatch, getState) => {
    sendMessage(messageObj)
      .then((response) => {
        dispatch(confirmedSendMessageAction(response.data));
        swal("Great!", "Your message has been sent successfully", "success");
      })
      .catch((error) => {
        console.log(error);
        dispatch(sendMessageFailedAction(error.response.data));
      });
  };
}

export function confirmedUpdatePartnerAction(_partner) {
  return {
    type: CONFIRMED_UPDATE_PARTNER_ACTION,
    payload: _partner,
  };
}

export function getPartnersFailedAction(error) {
  return {
    type: FAILED_GET_PARTNERS_ACTION,
    payload: error,
  };
}

// update pasword of the user
export function updatePasswordAction(oldPassword, newPassword, confirmPassword) {
  return (dispatch, getState) => {
    updatePasswordService(oldPassword, newPassword, confirmPassword)
      .then((response) => {
        const { success, message } = response.data;

        swal("Success", message, "success");
      })
      .catch((error) => {
        const { message } = error.response.data;
        // console.log(error);
        swal("Error", message, "error");
        dispatch(updatePartnerFailedAction(error.response.data));
      });
  };
}

// export function updatePasswordAction(oldPassword, newPassword, confirmPassword) {
//   return (dispatch, getState) => {
//     updatePasswordService(oldPassword, newPassword, confirmPassword)
//       .then((response) => {
//         swal("Success", "Password updated successfully!", "success");

//         dispatch(partnersToggleLoadingAction(false));
//       })
//       .catch ((error)=> {
//       formatError(error.response?.data);
//       dispatch(updatePartnerFailedAction(error.response?.data));
//       throw error;
//     })
//   };
// }

export function confirmedSendMessageAction(data) {
  return {
    type: "CONFIRMED_SEND_MESSAGE",
    payload: data,
  };
}

export function sendMessageFailedAction(error) {
  return {
    type: "SEND_MESSAGE_FAILED",
    payload: error,
  };
}
