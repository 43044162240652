//export const GET_POOLSS = '[pools Action] Get PARTNERS';
export const CONFIRMED_GET_EARNINGS_ACTION = "[earnings Action] Confirmed Get EARNINGS";

// export const CONFIRMED_GET_REFERRED_PARTNERS_ACTION = "[earnings Action] Confirmed Get Referred PARTNERS";

// export const CONFIRMED_UPDATE_PARTNER_ACTION = "[earnings Action] Confirmed Update PARTNER";

// export const FAILED_GET_PARTNERS_ACTION = "[earnings Action] Failed Get PARTNERS";

// export const PARTNERS_NOT_FOUND_ACTION = "[earnings Action] Not Found PARTNERS";
