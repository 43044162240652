import axiosInstance from "./AxiosInstance";
import swal from "sweetalert";

//Read All processed Deposits for Admin only
export function getProcessedDeposits() {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "partnerDeposits/processed";
  //console.log(localStorage.jwt);
  let token = JSON.parse(localStorage.jwt);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return axiosInstance.get(url, config);
}

//Read All transactions of a logged in User
export function getUnProcessedDeposits() {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "partnerDeposits/unProcessed";
  //console.log(localStorage.jwt);
  let token = JSON.parse(localStorage.jwt);

  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return axiosInstance.get(url, config);
}

//Read All transactions of a logged in User
export function transferUsdt(_depositDoc) {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "partnerDeposits/transferDeposits";
  //console.log(localStorage.jwt);
  let token = JSON.parse(localStorage.jwt);

  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  return axiosInstance.put(url, _depositDoc, { headers });
}

//Read All transactions of a logged in User
export function transferAllUsdt() {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "partnerDeposits/transferAllDeposits";
  //console.log(localStorage.jwt);
  let token = JSON.parse(localStorage.jwt);
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  return axiosInstance.put(url, {}, { headers });
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "User alredy registered":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "Invalid email or password":
      //return 'Email not found';
      swal("Oops", "Invalid Email or Password ", "error", {
        button: "Try Again!",
      });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}
