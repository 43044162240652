//export const CREATE_POOLS_ACTION = '[deopsits Action] Create pools';
export const CONFIRMED_CREATE_DEPOSITS_ACTION = "[deopsits Action] Confirmed Create Deposits";

//export const GET_POOLSS = '[deopsits Action] Get POOLSs';
export const CONFIRMED_GET_PROCESSED_DEPOSITS_ACTION = "[deopsits Action] Confirmed Get ProcessedDeposits";

export const CONFIRMED_GET_UNPROCESSED_DEPOSITS_ACTION = "[deopsits Action] Confirmed Get Un-Processed Deposits";

export const CONFIRMED_TRANSFER_USDT_ACTION = "[deopsits Action] Confirmed Transfer USDT";

export const CONFIRMED_TRANSFER_USD_FAILED_ACTION = "[deopsits Action] Confirmed Transfer Failed USDT";

export const CONFIRMED_GET_TOTALS_ACTION = "[deopsits Action] Confirmed Get Totals";

export const CONFIRMED_GET_WITHRAWAL_REQUEST_DEPOSITS_ACTION = "[deopsits Action] Confirmed Get Withdrawl Request Deposits";

export const CONFIRMED_UPDATE_WITHRAWAL_REQUEST_DEPOSITS_ACTION = "[deopsits Action] Confirmed Update Withdrawl Request Deposits";

export const GET_DEPOSITS_FAILED_ACTION = "[deopsits Action] Confirmed Get Request POOLSs";

//export const EDIT_POOLS_ACTION = '[deopsits Action] Edit pools';
export const LOADING_TOGGLE_ACTION = "[deopsits Action] Confirmed Loading Toggle Deposits";

export const CONFIRMED_DELETE_DEPOSITS_ACTION = "[deopsits Action] Confirmed Delete Deposits";
