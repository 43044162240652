import React, { useState, useEffect } from "react";
import { Dropdown, Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import LogoutPage from "./Logout";
import TopUpAndWithdraw from "../../components/TopUpAndWithdraw";

import profile from "../../../images/profileIcon.png";

const Header = ({ onNote }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [rightSelect, setRightSelect] = useState("Eng");

  const partner = JSON.parse(localStorage.userDetails);

  //For fix header
  const [headerFix, setheaderFix] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //const [searchBut, setSearchBut] = useState(false);
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;

  return (
    <>
      <ToastContainer />
      <div className={`header ${headerFix ? "is-fixed" : ""}`}>
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div className="dashboard_bar" style={{ textTransform: "capitalize" }}>
                  {finalName.join(" ").length === 0 ? "Dashboard" : finalName.join(" ") === "dashboard dark" ? "Dashboard" : finalName.join(" ")}
                </div>
                <div className="sell-blance">
                  <div className="input-tier-group">
                    <span className="input-group-tier-text">Tier &nbsp;{partner.tier}</span>
                  </div>
                </div>
              </div>
              <div className="navbar-nav header-right">
                <div className="nav-item d-flex align-items-center">
                  <div className="input-group">
                    <div className="row">
                      {windowWidth > 506 && <TopUpAndWithdraw />}
                      <div className="col">
                        {" "}
                        <div className="d-grid gap-2">
                          <Dropdown as="li" className="nav-item dropdown header-profile">
                            <Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
                              <div className="profile-container">
                                <img className="profile-picture" src={partner.image_url ? partner.image_url : profile} width={20} alt="" />
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="right" className="dropdown-menu dropdown-menu-end">
                              <Link to="/profile" className="dropdown-item ai-icon">
                                <svg
                                  id="icon-user1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="text-primary me-1"
                                  width={18}
                                  height={18}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                  <circle cx={12} cy={7} r={4} />
                                </svg>
                                <span className="ms-2">Profile </span>
                              </Link>

                              <LogoutPage />
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                  /> */}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  showLoading: state.showLoading,
  error: state.error,
});

export default connect(mapStateToProps)(Header);
