import { act } from "react-dom/test-utils";
import { CONFIRMED_GET_EARNINGS_ACTION } from "../actions/EarningsTypes";

const initialState = {
  profits: [],
  rewards: [],
  incentives: [],
  isLoading: true,
  error: null,
};

export default function EarningsReducer(state = initialState, action) {
  switch (action.type) {
    case CONFIRMED_GET_EARNINGS_ACTION:
      return {
        ...state,
        profits: action.payload.profits,
        rewards: action.payload.rewards,
        incentives: action.payload.incentives,
        isLoading: false,
      };
    default:
      return state;
  }
}
