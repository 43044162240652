import React, { useState, useEffect, useRef, Fragment, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import { getReferredPartnersAction } from "../../store/actions/PartnersActions";
import { Row, Card, Tab, Nav, Button, ListGroup } from "react-bootstrap";

const Rewards = (props) => {
  const dispatch = useDispatch();
  const partner = JSON.parse(localStorage.userDetails);
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const inputRef = useRef(null); // Create a ref to the input element
  const handleCopyClick = () => {
    if (inputRef.current) {
      inputRef.current.select(); // Select the input's text
      navigator.clipboard
        .writeText(inputRef.current.value) // Copy the selected text to the clipboard
        .then(() => {
          setCopyButtonText("Copied");
          setTimeout(() => {
            setCopyButtonText("Copy");
          }, 10000);
        })
        .catch((error) => {
          console.error("Error copying text:", error);
        });
    }
  };
  useEffect(() => {
    //  dispatch(loadingToggleAction(true));
    dispatch(getReferredPartnersAction());
  }, []);
  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        {/* //////////////////////Referral Link///////////////////////////////////////// */}
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Referral Link</h4>
            </div>
            <div className="card-body">
              <div className="input-group mb-3">
                <input type="text" className="form-control" defaultValue={process.env.REACT_APP_MYARBIT_DashboardUrl + `register?ref=${partner.referral_code}`} disabled ref={inputRef} />
                <button className="btn btn-primary" type="button" onClick={handleCopyClick}>
                  {copyButtonText}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Card>
          {/* <Card.Header>
              <Card.Title>Default Tab</Card.Title>
            </Card.Header> */}
          <Card.Body>
            {/* <!-- Nav tabs --> */}
            <div className="default-tab">
              <Tab.Container defaultActiveKey="how-it-works">
                <Nav as="ul" className="nav-tabs">
                  <Nav.Item as="li">
                    <Nav.Link eventKey="how-it-works">How It Works</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="referred">Referred Partners</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="pt-4">
                  {/* //////////////////////How it Works///////////////////////////////////////// */}
                  <Tab.Pane eventKey="how-it-works">
                    {/* <h4>This is Un-Processed title</h4> */}
                    <div className="card-body">
                      <Card.Title>🚀 Unlock the Power of Referrals with Our System 🚀 </Card.Title>
                      <Card.Text>
                        Are you ready to take your earnings to the next level? Introducing our innovative referral system, designed to reward you for spreading the word about our products or services.
                        With our dynamic referral program, your potential for earning is limitless!
                      </Card.Text>

                      <Card.Title>🌟 How Our Referral System Works 🌟</Card.Title>
                      <Card.Text>At the heart of our referral system is a simple yet powerful idea: the more you refer, the more you earn. Here's how it works:</Card.Text>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          <span className="highlight">📈 Tiered Rewards</span>: We understand that not all referrals are created equal. That's why we offer tiered rewards based on your referral level.
                          Whether you're just starting or have a well-established network, there's a reward tier for you.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span className="highlight">🌐 Referral Levels</span>: Your referral level is determined by the number of successful referrals you make. As you reach different milestones,
                          your earnings increase. For example:
                          <ListGroup variant="flush">
                            <ListGroup.Item>
                              <strong> Level 1: On getting upto 5 referrals you'll earn a generous 10% of the profit for each successful referral.</strong>{" "}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <strong> Level 2: On getting from 6 to 10 referrals your reward jumps to an impressive 12%.</strong>{" "}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <strong> Level 3: On getting from 11 to 15 referrals enjoy a fantastic 15% reward.</strong>{" "}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <strong> Level 4: On getting from 16 to 20 referrals a remarkable 17% of the profit is yours.</strong>{" "}
                            </ListGroup.Item>
                            <ListGroup.Item>
                              <strong> Level 5: On getting from 21 and above referrals the sky's the limit with an incredible 20% reward!</strong>{" "}
                            </ListGroup.Item>
                          </ListGroup>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span className="highlight">💡 Unlimited Potential</span>: The beauty of our system is its scalability. There's no cap on how many referrals you can make, and your rewards
                          stack as you climb through the referral levels. The more you refer, the more you earn, with no upper limit.
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span className="highlight">💰 Real Rewards, Real Money</span>: Your rewards are calculated based on the profit generated from your referrals, and they translate directly
                          into real money. Imagine the possibilities of a passive income stream that keeps growing!
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <span className="highlight">👥 Empower Others</span>: Not only do you benefit, but you also empower others to enjoy our products or services. Sharing the value has never been
                          so rewarding.
                        </ListGroup.Item>
                      </ListGroup>
                      <Card.Text>
                        <span className="highlight">👉 Get Started Today</span>: Join our referral program and start earning immediately. It's as simple as referring friends, family, or colleagues
                        using your unique referral link. Watch your earnings grow with every successful referral.
                      </Card.Text>
                      <Card.Text>
                        👉 Don't miss out on this fantastic opportunity to boost your income and share the benefits of our products or services with others. With our referral system, everyone wins!
                      </Card.Text>
                    </div>
                  </Tab.Pane>
                  {/* //////////////////////Referred 8iiiiiiiiiiiiiiiiiiiiiii[---------------=///////////////////////////////////////// */}
                  <Tab.Pane eventKey="referred">
                    {/* <h4>This is Processed title</h4> */}
                    <div className="card-body">
                      <div className="table-responsive recentOrderTable">
                        <table className="table verticle-middle table-responsive-md">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Email</th>
                              {/* <th scope="col">Created At</th>
                      <th scope="col">Status</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {props.partners.referredPartners &&
                              props.partners.referredPartners.map((partner) => (
                                <tr key={partner.id}>
                                  {/* <td>
                            <span class={partner.type === "deposit" ? "badge bg-success badge-lg" : "badge-danger badge badge-lg"}>{partner.type}</span>
                          </td> */}
                                  <td>{partner.name}</td>
                                  <td>{partner.email}</td>
                                  {/* <td>
                            <span class={transaction.status === "Served" ? "text-success" : "text-danger"}>{transaction.status}</span>
                          </td> */}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  showLoading: state.showLoading,
  error: state.error,
});

export default connect(mapStateToProps)(Rewards);
