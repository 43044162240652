export const isAuthenticated = (state) => {
  if (state.auth.auth.idToken) return true;
  return false;
};

export const isAdmin = (state) => {
  if (state.auth.auth.user.role === "admin") return true;
  return false;
};

export const isActive = (state) => {
  if (state.auth.auth.user.is_active) return true;
  return false;
};

export const isProfileComplete = (state) => {
  if (state.auth.auth.user.is_complete) return true;
  return false;
};
