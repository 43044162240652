import { LOADING_TOGGLE_ACTION, CONFIRMED_GET_PROCESSED_DEPOSITS_ACTION, CONFIRMED_GET_UNPROCESSED_DEPOSITS_ACTION, CONFIRMED_TRANSFER_USDT_ACTION } from "../actions/PartnerDepositsTypes";

const initialState = {
  processed: [],
  unProcessed: [],
  showLoading: false,
  error: null,
};

export default function PartnerDepositsReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_TOGGLE_ACTION:
      return {
        ...state,
        showLoading: action.payload,
      };
    case CONFIRMED_GET_PROCESSED_DEPOSITS_ACTION:
      return {
        ...state,
        processed: action.payload,
        showLoading: false,
      };

    case CONFIRMED_GET_UNPROCESSED_DEPOSITS_ACTION:
      return {
        ...state,
        unProcessed: action.payload,
        showLoading: false,
      };

    case CONFIRMED_TRANSFER_USDT_ACTION:
      return {
        ...state,
        unProcessed: action.payload,
        showLoading: false,
      };
    // case CONFIRMED_GET_TOTALS_ACTION:
    //   return {
    //     ...state,
    //     totals: action.payload,
    //     showLoading: false,
    //   };
    // case CONFIRMED_GET_WITHRAWAL_REQUEST_TRANSACTIONS_ACTION:
    //   return {
    //     ...state,
    //     transactions: action.payload,
    //     showLoading: false,
    //   };
    // case CONFIRMED_UPDATE_WITHRAWAL_REQUEST_TRANSACTIONS_ACTION:
    //   return {
    //     ...state,
    //     showLoading: false,
    //   };
    // case GET_TRANSACTIONS_FAILED_ACTION:
    //   return {
    //     ...state,
    //     showLoading: false,
    //     error: action.payload,
    //   };
    default:
      return state;
  }
}
