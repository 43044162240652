import { act } from "react-dom/test-utils";
import {
  CONFIRMED_GET_PARTNERS_ACTION,
  CONFIRMED_GET_PARTNER_BY_PHONE_ACTION,
  CONFIRMED_GET_PARTNER_BY_EMAIL_ACTION,
  CONFIRMED_GET_PARTNERS_WITH_PAGINATION_ACTION,
  FAILED_GET_PARTNERS_ACTION,
  CONFIRMED_UPDATE_PARTNER_ACTION,
  PARTNERS_NOT_FOUND_ACTION,
  CONFIRMED_GET_REFERRED_PARTNERS_ACTION,
  PARTNERS_TOGGLE_LOADING_ACTION,
} from "../actions/PartnersTypes";

const initialState = {
  partners: [],
  partner_by_phone: {},
  partner_by_email: {},
  partners_pagination: [],
  total_pages: 0,
  page_num: 1,
  total_partners: 0,
  total_depositors: 0,
  referredPartners: [],
  isLoading: true,
  showLoading: false,
  error: null,
};

export default function PartnersReducer(state = initialState, action) {
  switch (action.type) {
    case CONFIRMED_GET_PARTNERS_ACTION:
      return {
        ...state,
        partners: action.payload,
        isLoading: false,
      };

    case CONFIRMED_GET_PARTNER_BY_PHONE_ACTION:
      return {
        ...state,
        partner_by_phone: action.payload,
        isLoading: false,
      };
    case CONFIRMED_GET_PARTNER_BY_EMAIL_ACTION:
      return {
        ...state,
        partner_by_email: action.payload,
        isLoading: false,
      };

    case CONFIRMED_GET_PARTNERS_WITH_PAGINATION_ACTION:
      return {
        ...state,
        partners_pagination: action.payload.users,
        total_pages: action.payload.totalPages,
        page_num: action.payload.pageNum,
        total_partners: action.payload.totalPartners,
        total_depositors: action.payload.totalDepositors,
        isLoading: false,
      };
    case CONFIRMED_GET_REFERRED_PARTNERS_ACTION:
      return {
        ...state,
        referredPartners: action.payload,
        isLoading: false,
      };

    case CONFIRMED_UPDATE_PARTNER_ACTION:
      return {
        ...state,
        isLoading: false,
      };
    case FAILED_GET_PARTNERS_ACTION:
      return {
        ...state,
        isLoading: false,
      };
    case PARTNERS_NOT_FOUND_ACTION:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case PARTNERS_TOGGLE_LOADING_ACTION:
      return {
        ...state,
        showLoading: action.payload,
      };
    default:
      return state;
  }
}
