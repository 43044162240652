//export const GET_POOLSS = '[pools Action] Get PARTNERS';
export const CONFIRMED_GET_PARTNERS_ACTION = "[partners Action] Confirmed Get PARTNERS";

export const CONFIRMED_GET_PARTNER_BY_PHONE_ACTION = "[partners Action] Confirmed Get PARTNER By Phone";

export const CONFIRMED_GET_PARTNER_BY_EMAIL_ACTION = "[partners Action] Confirmed Get PARTNER By Email";

export const CONFIRMED_GET_PARTNERS_WITH_PAGINATION_ACTION = "[partners Action] Confirmed Get with Pagination PARTNERS";

export const CONFIRMED_GET_REFERRED_PARTNERS_ACTION = "[partners Action] Confirmed Get Referred PARTNERS";

export const CONFIRMED_UPDATE_PARTNER_ACTION = "[partners Action] Confirmed Update PARTNER";

export const FAILED_GET_PARTNERS_ACTION = "[partners Action] Failed Get PARTNERS";

export const PARTNERS_NOT_FOUND_ACTION = "[partners Action] Not Found PARTNERS";

export const PARTNERS_TOGGLE_LOADING_ACTION = "[partners Action] Toggle Loading";
