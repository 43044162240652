import React, { useState, useEffect, useRef, Fragment, Suspense } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

import swal from "sweetalert";
import { connect, useDispatch } from "react-redux";
import { getAllActivePoolsAction } from "../../store/actions/PoolsActions";
import { createStakingsAction } from "../../store/actions/ManageStakingsAction";
import { calculateProfitPercentage } from "../../services/PartnersService";

import { Dropdown, Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Intercom from "@intercom/messenger-js-sdk";

const Pools = (props) => {
  const progressBarRef = useRef(null);

  const reduxDispatch = useDispatch();
  const [autoStakIsChecked, setAutoStakIsChecked] = useState(false);
  const [modalVisibility, setModalVisibility] = useState([]);
  const user = localStorage.userDetails ? JSON.parse(localStorage.userDetails) : "";

  const toggleSwitch = () => {
    setAutoStakIsChecked(!autoStakIsChecked);
  };

  const openModal = (index) => {
    const updatedVisibility = [...modalVisibility];
    updatedVisibility[index] = true;
    setModalVisibility(updatedVisibility);
  };

  const closeModal = (index) => {
    const updatedVisibility = [...modalVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setModalVisibility(updatedVisibility);
  };

  const navigate = useNavigate();

  //To get staking amount for staking creation
  const [stakeAmount, setStakeAmount] = useState();
  let errorsObj = { amount: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    reduxDispatch(getAllActivePoolsAction());
    // console.log(user);
    if (user) {
      Intercom({
        app_id: "kv0kcopk",
        // user_id: user.id,
        id: user.id,
        image_url: user.image_url,
        name: user.name,
        account_type: user.account_type,
        email: user.email,
        gender: user.gender,
        balance: user.balance,
        is_verified: user.is_verified,
        tier: user.tier,
        role: user.role,
        total_staked: user.total_staked,
        referral_code: user.referral_code,
        wallet_address: user.wallet_address,
        phone: user.phone,
        country: user.country,
        is_active: user.is_active,
        is_complete: user.is_complete,
        referral_level: user.referral_level,
        isPass: user.password ? true : false,
      });
    }

    return () => {
      // Cleanup if needed when the component is unmounted
      if (window.Intercom) {
        window.Intercom("shutdown");
      }
    };
  }, []);

  function handleCreateStaking(e, index) {
    e.preventDefault();
    let user = JSON.parse(localStorage.getItem("userDetails"));
    if (!user.is_verified) {
      toast.warn("❗ Please verify your email first to enable your staking ability!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
      // setIsVerifiedWaring(false);
    }
    const form = e.target;
    const poolId = form.elements.poolId.value;
    const minStake = form.elements.minStake.value;
    const autoStake = form.elements.autoStake.value;

    let error = false;
    const errorObj = { ...errorsObj };
    if (stakeAmount === "") {
      errorObj.amount = "Staking Amount must be valid number...";
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }
    if (stakeAmount === "0") {
      errorObj.amount = "Staking Amount must be greater than 0...";
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }

    if (JSON.parse(stakeAmount) > user.balance.toFixed(3)) {
      errorObj.amount = "Insufficient Balance.";
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }

    if (JSON.parse(stakeAmount) < minStake) {
      errorObj.amount = `Please enter an amount greater than ${minStake}`;
      error = true;
      setErrors(errorObj);
      if (error) {
        return;
      }
    }
    //console.log(e);
    //reduxDispatch(createStakingsAction());
    reduxDispatch(createStakingsAction(stakeAmount, poolId, autoStake, navigate));
    setStakeAmount("");
    closeModal(index);
    /* {
      Model should be closed here.
    } */
  }

  function handleStakeAll(e, index) {
    e.preventDefault();
    if (stakeAmount === 0 || stakeAmount === "" || stakeAmount === undefined) {
      setStakeAmount(parseFloat(user.balance.toFixed(3)));
    } else {
      // If stakeAmount is not 0, reset it to 0
      setStakeAmount("");
    }
  }
  function openInfoAlert() {
    swal("Info!", "If turned on this will automatically stake again when staking period ends.", "success");
  }
  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Investment Pools List</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive recentOrderTable">
                <div className="table-container">
                  <table className="table verticle-middle table-responsive-md">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Duration(days)</th>
                        <th scope="col">Min-Stake</th>
                        <th scope="col">Profit</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.pools.pools.map((pool, index) => (
                        <tr key={index}>
                          <td>{pool.name}</td>
                          <td>{pool.duration}</td>
                          <td>{pool.min_stake}</td>
                          <td>{calculateProfitPercentage(user.tier, pool.duration, user.account_type)}</td>
                          <td>
                            <Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={() => openModal(index)}>
                              Stake
                            </Button>
                            <Modal className="modal fade" show={modalVisibility[index]} onHide={() => closeModal(index)}>
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">Specify Staking Amount</h5>
                                  <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeModal(index)}>
                                    <span>×</span>
                                  </Button>
                                </div>
                                <div className="modal-body">
                                  <form className="comment-form" onSubmit={(e) => handleCreateStaking(e, index)}>
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="form-group mb-3">
                                          <label htmlFor="author" className="text-black font-w600">
                                            {" "}
                                            Amount <span className="required">*</span>
                                            {""}
                                          </label>
                                          <span className="text-green font-w300"> &nbsp;Available Balance - {user.balance.toLocaleString()}</span>
                                          {""}

                                          <input
                                            type="Number"
                                            className="form-control"
                                            value={stakeAmount}
                                            onChange={(e) => setStakeAmount(e.target.value)}
                                            name="stakeAmount"
                                            placeholder="Enter Staking Amount"
                                          />
                                          {errors.amount && <div className="text-danger fs-12">{errors.amount}</div>}
                                          <input type="text" value={pool._id} style={{ visibility: "hidden" }} onChange={(e) => setStakeAmount(e.target.value)} name="poolId" placeholder="Author" />
                                          <input
                                            type="text"
                                            value={pool.min_stake}
                                            style={{ visibility: "hidden" }}
                                            onChange={(e) => setStakeAmount(e.target.value)}
                                            name="minStake"
                                            placeholder="Author"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-4 col-4">
                                        <div className="input-group-append">
                                          <button type="button" className="btn btn-primary" onClick={handleStakeAll}>
                                            Stake All
                                          </button>
                                        </div>
                                      </div>
                                      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-4 col-4">
                                        <div className="form-group mb-3">
                                          <input type="submit" value="Confirm Staking" className="submit btn btn-primary" name="submit" />
                                        </div>
                                      </div>
                                      <div class="form-check form-switch">
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          id="flexSwitchCheckChecked"
                                          name="autoStake"
                                          value={autoStakIsChecked}
                                          checked={autoStakIsChecked}
                                          onChange={toggleSwitch}
                                        />
                                        <label class="form-check-label" for="flexSwitchCheckChecked">
                                          Turn Auto Restake On
                                        </label>
                                        <a as="a" href="#" onClick={() => openInfoAlert()}>
                                          <i class="la la-question" style={{ color: "#000000" }}></i>
                                        </a>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </Modal>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  pools: state.pools,
  isLoading: state.isLoading,
  error: state.error,
});

export default connect(mapStateToProps)(Pools);
