import { getPartnerEarnings } from "../../services/EarningsService";
import { CONFIRMED_GET_EARNINGS_ACTION } from "./EarningsTypes";
import swal from "sweetalert";

//Action for reading all the earning of a Logged In partner
export function getPartnerEarnigsAction() {
  return (dispatch, getState) => {
    getPartnerEarnings()
      .then((response) => {
        dispatch(confirmedGetPartnerEarnigsAction(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function confirmedGetPartnerEarnigsAction(earnings) {
  return {
    type: CONFIRMED_GET_EARNINGS_ACTION,
    payload: earnings,
  };
}
