//export const CREATE_POOLS_ACTION = '[stakings Action] Create pools';
export const CONFIRMED_CREATE_STAKINGS_ACTION = "[stakings Action] Confirmed Create Stakings";

//export const GET_POOLSS = '[stakings Action] Get POOLSs';
export const CONFIRMED_GET_STAKINGS_ACTION = "[stakings Action] Confirmed Get Stakings";

export const CONFIRMED_UPDATE_STAKINGS_ACTION = "[stakings Action] Confirmed Update Stakings";

export const GET_STAKINGS_FAILED_ACTION = "[stakings Action] Confirmed Get Request POOLSs";

//export const EDIT_POOLS_ACTION = '[stakings Action] Edit pools';
export const LOADING_TOGGLE_ACTION = "[stakings Action] Confirmed Edit Stakings";

export const CONFIRMED_DELETE_STAKINGS_ACTION = "[stakings Action] Confirmed Delete Stakings";
