import { Dropdown, Button, Modal, Container, Row, Col } from "react-bootstrap";

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import QRCode from "qrcode.react";

import { connect, useDispatch } from "react-redux";
import swal from "sweetalert";
import { withdrawPartnerAction, sendVerificationCodeAction } from "../../store/actions/PartnersActions";

const TopUpAndWithdraw = () => {
  const [topUpModal, setTopUpModal] = useState(false);
  const [withDrawModal, setWithDrawModal] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState();
  const [walletAddress, setWalletAddress] = useState();
  const [withdrawCode, setWithdrawCode] = useState();
  const partner = JSON.parse(localStorage.userDetails);
  const [sendEmail, setSendEmail] = useState(false);
  const [emailTimer, setEmailTimer] = useState(60); // State to track remaining time

  const dispatch = useDispatch();

  const notifyTopFullWidth = (msg) => {
    toast.warn(`✔️ Please check your Email for Withdraw Verification Code.`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function handleSendVerificationCode() {
    // console.log(localStorage.userDetails);
    notifyTopFullWidth();
    dispatch(sendVerificationCodeAction());
    setSendEmail(true);
    setTimeout(() => {
      setSendEmail(false);
    }, 60000);
  }
  function handleWithdraw(e) {
    e.preventDefault();
    // console.log(localStorage.userDetails);
    const userDetails = JSON.parse(localStorage.userDetails);
    if (userDetails.balance < withdrawAmount)
      return swal("Oops", `You have ${userDetails.balance} USDT in your account. Please withdraw with in your available balance.`, "error", { button: "Try Again!" });
    // console.log(withdrawAmount);
    if (withdrawAmount < 50) return swal("Sorry", "Minimum withdrawlable amount is 50 USDT.", "error", { button: "Try Again!" });

    dispatch(withdrawPartnerAction(withdrawAmount, walletAddress, withdrawCode));

    closeWithDrawModal();
  }
  const closeTopUpModal = () => {
    setTopUpModal(false);
  };
  function handleTopUp(e) {
    e.preventDefault();
    // dispatch(topUpPartnerAction(topUpAmount));
    // setTimeout(() => {
    //   dispatch(getPartnersAction());
    // }, 2000);
    closeTopUpModal();
  }
  const openTopUpModal = () => {
    // console.log(partner);
    // if (!partner.wallet_address) return notifyTopCenter();
    setTopUpModal(true);
  };
  const openWithdrawModal = (partner) => {
    setWithDrawModal(true);
  };
  const closeWithDrawModal = () => {
    setWithDrawModal(false);
  };

  // useEffect to update the timer every second
  useEffect(() => {
    // let user = JSON.parse(localStorage.userDetails);
    // setPartner(user);
    // timer every second
    let timerInterval;
    if (sendEmail) {
      timerInterval = setInterval(() => {
        setEmailTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      setEmailTimer(60);
      clearInterval(timerInterval);
    };
  }, [sendEmail]);

  return (
    <>
      {/*******************************Deposit-Up Modal *****************************************/}
      <Modal className="modal fade" style={{ zIndex: 10 }} show={topUpModal} onHide={() => closeTopUpModal()}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Deposit</h5>
            <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeTopUpModal()}>
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            {/* Form for Pool Creation */}
            <form className="comment-form" onSubmit={(e) => handleTopUp(e)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Wallet Address
                      {/* <span className="required">*</span> */}
                      {""}
                    </label>

                    <Row className="justify-content-md-center mb-2">
                      <Col md={{ span: 6, offset: 3 }}>
                        <div className="centered-div">
                          <QRCode className="justify-content-center align-items-center" value={partner.wallet_address} />
                        </div>
                      </Col>
                    </Row>

                    <input type="text" value={partner.wallet_address} className="form-control" name="partnerName" disabled />
                    <p>Please transfer USDT(TRC20) from your preffered exchange to the above wallet address</p>
                    {/* <input type="text" value={pool._id} style={{ visibility: "hidden" }} name="poolId" placeholder="POOL_ID" /> */}

                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-12 justify-content-center align-items-center"></div>

                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <input type="submit" value="OK" className="submit btn btn-primary" name="submit" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      {/******************************* Withdraw Modal *****************************************/}
      <Modal className="modal fade" style={{ zIndex: 10 }} show={withDrawModal} onHide={() => closeWithDrawModal()}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Withdraw</h5>
            <Button variant="" type="button" className="close" x data-dismiss="modal" onClick={() => closeWithDrawModal()}>
              <span>×</span>
            </Button>
          </div>
          <div className="modal-body">
            {/* Form for Pool Creation */}
            <form className="comment-form" onSubmit={(e) => handleWithdraw(e)}>
              <div className="row">
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Withdraw Amount <span className="required">*</span>
                      {""}
                    </label>
                    <input type="Number" value={withdrawAmount} className="form-control" onChange={(e) => setWithdrawAmount(e.target.value)} name="partnerName" placeholder="Enter Amount" />
                    <div className="text-danger fs-12">2 USDT network fee</div>

                    {/* <input type="text" value={pool._id} style={{ visibility: "hidden" }} name="poolId" placeholder="POOL_ID" /> */}

                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Network <span className="required">*</span>
                      {""}
                    </label>
                    <input type="Text" value={"TRON TRC20"} className="form-control" name="partnerName" placeholder="" disabled />
                    {/* <input type="text" value={pool._id} style={{ visibility: "hidden" }} name="poolId" placeholder="POOL_ID" /> */}

                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Reciever Address <span className="required">*</span>
                      {""}
                    </label>
                    <input type="Text" value={walletAddress} className="form-control" onChange={(e) => setWalletAddress(e.target.value)} name="partnerName" placeholder="Your Wallet Address" />
                    {/* <input type="text" value={pool._id} style={{ visibility: "hidden" }} name="poolId" placeholder="POOL_ID" /> */}

                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-1  align-items-end">
                    <label htmlFor="author" className="text-black font-w600">
                      {" "}
                      Verification Code <span className="required">*</span>
                      {""}
                    </label>
                    <div className=" fs-12">
                      You will revieve withdraw verification code on <strong>"{JSON.parse(localStorage.userDetails).email}"</strong>
                    </div>
                    <div className="form-group mb-3 d-flex align-items-end">
                      <input type="Text" value={withdrawCode} className="form-control" onChange={(e) => setWithdrawCode(e.target.value)} name="partnerName" placeholder="Code" />
                      <div className="col-lg-4">
                        {!sendEmail && (
                          <div className="text-center ">
                            <Button type="button" onClick={handleSendVerificationCode} className="btn btn-primary mb-1 ms-1">
                              Send Code
                            </Button>
                          </div>
                        )}
                        {sendEmail && (
                          <div className="text-center">
                            <Button type="button" disabled onClick={handleSendVerificationCode} className="btn btn-primary mb-1 ms-1">
                              Send Code ({emailTimer} seconds)
                            </Button>
                          </div>
                        )}
                        {/* <Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={handleSendVerificationCode}>
                          Send Code
                        </Button> */}
                      </div>
                    </div>
                    {/* <input type="text" value={pool._id} style={{ visibility: "hidden" }} name="poolId" placeholder="POOL_ID" /> */}

                    {/* {errors.poolName && <div className="text-danger fs-12">{errors.poolName}</div>} */}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group mb-3">
                    <input type="submit" value="Confirm" className="submit btn btn-primary" name="submit" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <div className="col" style={{ zIndex: 1 }}>
        <div className="d-grid gap-2">
          <Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={openTopUpModal}>
            {/* <i class="bi bi-download"></i> */}
            Deposit
          </Button>
        </div>
      </div>
      <div className="col" style={{ zIndex: 1 }}>
        <div className="d-grid gap-2">
          <Button as="a" href="#" className="btn btn-primary mb-1 ms-1" onClick={openWithdrawModal}>
            Withdraw
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  partners: state.partners,
  showLoading: state.showLoading,
  error: state.error,
});

export default connect(mapStateToProps)(TopUpAndWithdraw);
