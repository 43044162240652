import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_AUTHENTICATED_ACTION,
  LOGIN_UNAUTHENTICATE_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  SIGNUP_CONFIRMED_ACTION,
  SIGNUP_FAILED_ACTION,
  CONFIRMED_SIGNUP_REGISTER_EMAIL_ACTION,
  CONFIRMED_SIGNUP_EMAIL_VERIFIED_ACTION,
  CONFIRMED_SIGNUP_SET_PASSWORD_ACTION,
  // CONFIRMED_SIGNUP_LINK_RERERRAL_ACCOUNT_ACTION,
  CONFIRMED_SIGNUP_SET_PROMO_ACCOUNT_ACTION,
  AUTH_LOADING_TOGGLE_ACTION,
  CONFIRMED_SIGNUP_SET_ACCOUNT_TYPE_ACTION,
} from "../actions/AuthActions";

const initialState = {
  auth: {
    email: "",
    idToken: "",
    localId: "",
    expiresIn: "",
    refreshToken: "",
    user: {},
  },
  errorMessage: "",
  successMessage: "",
  isAuthenticated: false,
  showLoading: false,
  isReferred: false,
  isPromo: false,
};

export function AuthReducer(state = initialState, action) {
  if (action.type === CONFIRMED_SIGNUP_REGISTER_EMAIL_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: "Verification Email Sent Successfully.",
      showLoading: false,
    };
  }

  if (action.type === CONFIRMED_SIGNUP_EMAIL_VERIFIED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: " Partner Email Verified Successfully.",
      showLoading: false,
    };
  }
  if (action.type === CONFIRMED_SIGNUP_SET_PASSWORD_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: " Partner Password Set Successfully.",
      showLoading: false,
    };
  }
  if (action.type === CONFIRMED_SIGNUP_SET_PROMO_ACCOUNT_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: " PROMO_APPLIED",
      isPromo: true,
      showLoading: false,
    };
  }
  if (action.type === CONFIRMED_SIGNUP_SET_ACCOUNT_TYPE_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: " Partner Account Type Set Successfully.",
      showLoading: false,
    };
  }
  if (action.type === SIGNUP_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: "Signup Successfully Completed",
      showLoading: false,
    };
  }
  if (action.type === LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      auth: action.payload,
      errorMessage: "",
      successMessage: "Login Successfully Completed",
      showLoading: false,
    };
  }

  if (action.type === LOGIN_AUTHENTICATED_ACTION) {
    return {
      ...state,
      isAuthenticated: true,
      showLoading: false,
    };
  }
  if (action.type === LOGIN_UNAUTHENTICATE_ACTION) {
    return {
      ...state,
      isAuthenticated: false,
      showLoading: false,
    };
  }
  if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      errorMessage: "",
      successMessage: "",
      auth: {
        email: "",
        idToken: "",
        localId: "",
        expiresIn: "",
        refreshToken: "",
        user: {},
      },
    };
  }

  if (action.type === SIGNUP_FAILED_ACTION || action.type === LOGIN_FAILED_ACTION) {
    return {
      ...state,
      errorMessage: action.payload,
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === AUTH_LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }
  return state;
}
