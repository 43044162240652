import React, { useState, useEffect, Fragment, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import { getPartnerEarnigsAction } from "../../store/actions/EarningsActions";
import { Row, Card, Tab, Nav, Button, ListGroup } from "react-bootstrap";

const Earnings = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(loadingToggleAction(true));
    dispatch(getPartnerEarnigsAction());
  }, []);
  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    };
    const formattedDate = new Date(dateTimeString).toLocaleString("en-US", options);
    return formattedDate;
  };
  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <Card>
          {/* <Card.Header>
              <Card.Title>Default Tab</Card.Title>
            </Card.Header> */}
          <Card.Body>
            {/* <!-- Nav tabs --> */}
            <div className="default-tab">
              <Tab.Container defaultActiveKey="profits">
                <Nav as="ul" className="nav-tabs">
                  <Nav.Item as="li">
                    <Nav.Link eventKey="profits">Profits</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="rewards">Rewards</Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="incentives">Incentives</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="pt-4">
                  {/* //////////////////////Profits Tab///////////////////////////////////////// */}
                  <Tab.Pane eventKey="profits">
                    {/* <h4>This is Un-Processed title</h4> */}
                    <div className="card-body">
                      <div className="table-responsive recentOrderTable">
                        <table className="table verticle-middle table-responsive-md">
                          <thead>
                            <tr>
                              <th scope="col">Type</th>
                              <th scope="col">Invested Amount</th>
                              <th scope="col">Earned</th>
                              <th scope="col">Pool</th>
                              <th scope="col">Duration</th>
                              <th scope="col">tier</th>
                              <th scope="col">Profit(%)</th>
                              <th scope="col">Created At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.profits &&
                              props.profits.map((profit) => (
                                <tr key={profit.id}>
                                  <td>
                                    <span
                                      className={profit.type === "Profit" ? "badge bg-success badge-lg" : profit.type === "Incentive" ? "badge badge-warning badge-lg" : "badge badge-info badge-lg"}
                                    >
                                      {profit.type}
                                    </span>
                                  </td>
                                  <td>{profit.invested_amount}</td>
                                  <td>{profit.earning.toFixed(2)}</td>
                                  <td>{profit.pool_info.name}</td>
                                  <td>{profit.pool_info.duration}</td>
                                  <td>{profit.tier}</td>
                                  <td>{profit.profit_percent}</td>
                                  <td>{formatDate(profit.created_at)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Tab.Pane>
                  {/* //////////////////////Rewards Tab///////////////////////////////////////// */}
                  <Tab.Pane eventKey="rewards">
                    {/* <h4>This is Processed title</h4> */}
                    <div className="card-body">
                      <div className="table-responsive recentOrderTable">
                        <table className="table verticle-middle table-responsive-md">
                          <thead>
                            <tr>
                              <th scope="col">Type</th>
                              <th scope="col">Invested Amount</th>
                              <th scope="col">Earned</th>
                              <th scope="col">Created At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.rewards &&
                              props.rewards.map((earning) => (
                                <tr key={earning.id}>
                                  <td>
                                    <span className={earning.type === "Profit" ? "badge bg-success badge-lg" : earning.type === "Bonus" ? "badge badge-warning badge-lg" : "badge badge-info badge-lg"}>
                                      {earning.type}
                                    </span>
                                  </td>
                                  <td>{earning.invested_amount}</td>
                                  <td>{earning.earning}</td>
                                  <td>{formatDate(earning.created_at)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Tab.Pane>
                  {/* //////////////////////Bonus Tab///////////////////////////////////////// */}
                  <Tab.Pane eventKey="incentives">
                    {/* <h4>This is Processed title</h4> */}
                    <div className="card-body">
                      <div className="table-responsive recentOrderTable">
                        <table className="table verticle-middle table-responsive-md">
                          <thead>
                            <tr>
                              <th scope="col">Type</th>
                              <th scope="col">Earned</th>
                              <th scope="col">Created At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.incentives &&
                              props.incentives.map((earning) => (
                                <tr key={earning.id}>
                                  <td>
                                    <span className={earning.type === "Profit" ? "badge bg-success badge-lg" : earning.type === "Bonus" ? "badge badge-warning badge-lg" : "badge badge-info badge-lg"}>
                                      {earning.type}
                                    </span>
                                  </td>
                                  <td>{earning.earning}</td>
                                  <td>{formatDate(earning.created_at)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>

        {/* <div className="col-xl-12 col-lg-12 col-xxl-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Stakings History</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive recentOrderTable">
                <table className="table verticle-middle table-responsive-md">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.earnings.earnings &&
                      props.earnings.earnings.map((earning) => (
                        <tr key={earning.id}>
                          <td>
                            <span className={earning.type === "Profit" ? "badge bg-success badge-lg" : earning.type === "Bonus" ? "badge badge-warning badge-lg" : "badge badge-info badge-lg"}>
                              {earning.type}
                            </span>
                          </td>
                          <td>{earning.amount}</td>
                          <td>{formatDate(earning.created_at)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  profits: state.earnings.profits,
  rewards: state.earnings.rewards,
  incentives: state.earnings.incentives,
  isLoading: state.earnings.isLoading,
  error: state.error,
});

export default connect(mapStateToProps)(Earnings);
