import axiosInstance from "../services/AxiosInstance";
import swal from "sweetalert";

//Read all partners from Server
export function sendVerificationCode() {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/sendWithdrawCode";
  let token = JSON.parse(localStorage.jwt);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return axiosInstance.get(url, config);
}

//Read all partners from Server
export function getLoggedInParnter() {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/profile";
  let token = JSON.parse(localStorage.jwt);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return axiosInstance.get(url, config);
}

//Read all partners from Server
export function getUpdatedBalancePartner() {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/updatedBalace";
  let token = JSON.parse(localStorage.jwt);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return axiosInstance.get(url, config);
}

//Read all partners from Server for admins only
export function getPartners() {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user";
  let token = JSON.parse(localStorage.jwt);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return axiosInstance.get(url, config);
}

//Read all partners from Server for admins only
export function getPartnersWithPagination(_pageNum) {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user";
  let token = JSON.parse(localStorage.jwt);

  // Include the _pageNum parameter in the URL
  const queryParams = `pageNum=${_pageNum}`;
  const fullUrl = `${url}?${queryParams}`;

  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return axiosInstance.get(fullUrl, config);
}

//Read all partners from Server for admins only
export function getReferredPartners() {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/referred";
  let token = JSON.parse(localStorage.jwt);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return axiosInstance.get(url, config);
}

//Search partners from Server based on filters provided
export function partnerSearch(_dateRange, _country, _balanceFrom, _balanceTo, _stakeFrom, _stakeTo, _isReferred) {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/search";
  let token = JSON.parse(localStorage.jwt);
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  const queryPrams = {
    dateRange: _dateRange,
    country: _country,
    balanceFrom: _balanceFrom,
    balanceTo: _balanceTo,
    stakeFrom: _stakeFrom,
    stakeTo: _stakeTo,
    isReferred: _isReferred,
  };
  return axiosInstance.get(url, { params: queryPrams, headers: headers });
}

//Search partners from Server based on filters provided
export function partnerSearchByPhone(_phoneNumber) {
  try {
    const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/searchByPhone";
    let token = JSON.parse(localStorage.jwt);
    const headers = {
      "Content-Type": "application/json",
      "x-auth-token": token,
    };
    return axiosInstance.post(url, { phone: _phoneNumber }, { headers });
  } catch (err) {
    console.log(err);
  }
}

//Search partners from Server based on filters provided
export function partnerSearchByEmail(_partnerEmail) {
  try {
    const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/searchByEmail";
    let token = JSON.parse(localStorage.jwt);
    const headers = {
      "Content-Type": "application/json",
      "x-auth-token": token,
    };
    return axiosInstance.post(url, { email: _partnerEmail }, { headers });
  } catch (err) {
    console.log(err);
  }
}
//Update a partner on Server for admin
export function updatePartner(_partnerObj) {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/update/admin";
  let token = JSON.parse(localStorage.jwt);
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  const partnerObj = {
    id: _partnerObj.id,
    balance: _partnerObj.balance,
    is_verified: _partnerObj.is_verified,
    isBlocked: _partnerObj.isBlocked,
  };
  return axiosInstance.put(url, partnerObj, { headers });
}

//Send Verification email for Logged in partner
export function sendEmailVerificationLink() {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/sendVerificationEmail";
  const partnerObj = JSON.parse(localStorage.userDetails);
  let token = JSON.parse(localStorage.jwt);
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  return axiosInstance.post(url, partnerObj, { headers });
}

//Update a Logged In partner
export function updateLoggedInPartner(_partnerObj) {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user";
  let token = JSON.parse(localStorage.jwt);
  let user = JSON.parse(localStorage.userDetails);
  const data = {
    id: _partnerObj._id,
    name: _partnerObj.name,
    email: user.email,
    updatedEmail: _partnerObj.email,
    phone: _partnerObj.phone,
    country: _partnerObj.country,
  };
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  return axiosInstance.put(url, data, { headers });
}

//Update a Logged In partner
export async function uploadPartnerProfilePhoto(_partnerObj, _photo) {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/updateProfilePhoto";
  let token = JSON.parse(localStorage.jwt);

  const formData = new FormData();
  formData.append("_partnerObj", JSON.stringify(_partnerObj));
  formData.append("photo", _photo);
  // console.log(formData.get("_partnerObj"));
  const headers = {
    "Content-Type": "multipart/form-data",
    "x-auth-token": token,
  };
  try {
    const response = await axiosInstance.post(url, formData, { headers });

    return response;
  } catch (error) {
    console.error("Error uploading photo:", error);
    throw error; // Handle the error as needed
  }
}
//Widthdraw for Logged In partner
export function withdrawLoggedInPartner(_withdrawAmount, _walletAddress, _withdrawCode) {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/withdraw";
  let token = JSON.parse(localStorage.jwt);
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  return axiosInstance.put(url, { withdraw_amount: _withdrawAmount, wallet_address: _walletAddress, withdraw_code: _withdrawCode }, { headers });
}
//Block partner admin Only
export function blockPartner(_partnerId, _status) {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/block";
  let token = JSON.parse(localStorage.jwt);
  const data = {
    partnerId: _partnerId,
    status: !_status,
  };
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  return axiosInstance.put(url, data, { headers });
}

//Update verify status of partner by admin Only
export function updateVerifyStatus(_partnerId, _status) {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/verifyStatus";
  let token = JSON.parse(localStorage.jwt);
  const data = {
    partnerId: _partnerId,
    status: !_status,
  };
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };
  return axiosInstance.put(url, data, { headers });
}

export function formatError(errorResponse) {
  switch (errorResponse) {
    case "No Partners Found against applied Filters":
      swal("Oops", "No Partners found with the applied filters", "error", { button: "Try Again!" });
      return "No Partners Found";

    case "INVALID_PASSWORD":
      swal("Oops", "Old Password is invalid", "error", { button: "Try Again!" });
      return "Invalid Password";

    case "PASSWORD_DOES_NOT_MATCH":
      swal("Oops", "Your passwords do not match", "error", { button: "Try Again!" });
      return "Passwords Do Not Match";

    case "NO_PARTNER_FOUND":
      swal("Sorry", "No partner found with the provided details", "error", { button: "Try Again!" });
      return "No Partner Found";

    default:
      swal("Oops", "Something went wrong", "error", { button: "Try Again!" });
      return "Unknown Error";
  }
}

export function calculateProfitPercentage(_tier, _days, _acc_type) {
  let profitPercentage;
  switch (_tier) {
    case 1:
      switch (_days) {
        case 1:
          profitPercentage = 10;
          break;
        case 10:
          profitPercentage = 2.5;
          break;
        case 30:
          profitPercentage = 9;
          break;
        case 60:
          profitPercentage = 20;
          break;
        case 90:
          profitPercentage = 33;
          break;
        case 180:
          profitPercentage = 70;
          break;
        default:
          throw new Error("Invalid number of days for Tier1.");
      }
      break;
    case 2:
      switch (_days) {
        case 1:
          profitPercentage = 10;
          break;
        case 10:
          profitPercentage = 2.75;
          break;
        case 30:
          profitPercentage = 9.9;
          break;
        case 60:
          profitPercentage = 22;
          break;
        case 90:
          profitPercentage = 36.3;
          break;
        case 180:
          profitPercentage = 77;
          break;
        default:
          throw new Error("Invalid number of days for Tier2.");
      }
      break;
    case 3:
      switch (_days) {
        case 1:
          profitPercentage = 10;
          break;
        case 10:
          profitPercentage = 3;
          break;
        case 30:
          profitPercentage = 10.8;
          break;
        case 60:
          profitPercentage = 24;
          break;
        case 90:
          profitPercentage = 39.6;
          break;
        case 180:
          profitPercentage = 84;
          break;
        default:
          throw new Error("Invalid number of days for Tier3.");
      }
      break;
    case 4:
      switch (_days) {
        case 1:
          profitPercentage = 10;
          break;
        case 10:
          profitPercentage = 3.25;
          break;
        case 30:
          profitPercentage = 11.7;
          break;
        case 60:
          profitPercentage = 26;
          break;
        case 90:
          profitPercentage = 42.9;
          break;
        case 180:
          profitPercentage = 91;
          break;
        default:
          throw new Error("Invalid number of days for Tier4.");
      }
      break;
    case 5:
      switch (_days) {
        case 1:
          profitPercentage = 10;
          break;
        case 10:
          profitPercentage = 3.5;
          break;
        case 30:
          profitPercentage = 12.6;
          break;
        case 60:
          profitPercentage = 28;
          break;
        case 90:
          profitPercentage = 46.2;
          break;
        case 180:
          profitPercentage = 98;
          break;
        default:
          throw new Error("Invalid number of days for Tier5.");
      }
      break;
    case 6:
      switch (_days) {
        case 1:
          profitPercentage = 10;
          break;
        case 10:
          profitPercentage = 3.75;
          break;
        case 30:
          profitPercentage = 13.5;
          break;
        case 60:
          profitPercentage = 30;
          break;
        case 90:
          profitPercentage = 49.5;
          break;
        case 180:
          profitPercentage = 105;
          break;
        default:
          throw new Error("Invalid number of days for Tier6.");
      }
      break;
    case 7:
      switch (_days) {
        case 1:
          profitPercentage = 10;
          break;
        case 10:
          profitPercentage = 4;
          break;
        case 30:
          profitPercentage = 14.4;
          break;
        case 60:
          profitPercentage = 32;
          break;
        case 90:
          profitPercentage = 52.8;
          break;
        case 180:
          profitPercentage = 112;
          break;
        default:
      }
      break;
    default:
      throw new Error("Invalid tier.");
  }
  if (_acc_type === "Islamic") {
    // Calculate 10% of the input number
    const tenPercent = profitPercentage * 0.1;

    // Calculate the lower bound (10% less than the input number)
    const lowerBound = profitPercentage.toFixed(2) - tenPercent;

    // Calculate the upper bound (10% greater than the input number)
    const upperBound = profitPercentage + tenPercent;
    const profit_range = lowerBound.toFixed(2) + "% - " + upperBound.toFixed(2) + "%";
    return profit_range;
  } else {
    return profitPercentage + "%";
  }
}

//Update Password In partner
export function updatePasswordService(oldPassword, newPassword, confirmPassword) {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "user/updatePassword";
  let token = JSON.parse(localStorage.jwt);
  const data = { oldPassword, newPassword, confirmPassword };
  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };

  return axiosInstance.put(url, data, { headers });
}
//send massage from Contact us
export function sendMessage(messageObj) {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "message/send";
  let token = JSON.parse(localStorage.jwt);

  const headers = {
    "Content-Type": "application/json",
    "x-auth-token": token,
  };

  return axiosInstance.post(url, messageObj, { headers });
}
