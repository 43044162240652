import axiosInstance from "./AxiosInstance";
import swal from "sweetalert";

//Read all partners from Server
export function getPartnerEarnings() {
  const url = process.env.REACT_APP_MYARBIT_HostUrl + "earnings/partnerEarnings";
  let token = JSON.parse(localStorage.jwt);
  const config = {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token,
    },
  };
  return axiosInstance.get(url, config);
}
