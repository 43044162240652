import React, { useState,  Fragment, Suspense } from "react";
import { connect, useDispatch } from "react-redux";
import { sendMessageAction } from "../../store/actions/PartnersActions";
import Form from 'react-bootstrap/Form';

const ContactsUs = (props) => {
  const user = JSON.parse(localStorage.userDetails);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const messageObj = {
      user: user._id, 
      message: message,
    };
    dispatch(sendMessageAction(messageObj));
    setMessage("");
  };

  return (
    <Fragment>
      <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      >
        <div className="">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Contact Us</h4>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <Form>
                    <Form.Group className="mb-[500px]" controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Message</Form.Label>
                      <Form.Control 
                        as="textarea" 
                        rows={20} 
                        value={message}
                        onChange={handleMessageChange}
                        placeholder="Enter your message"
                        required
                        style={{ minHeight: "200px" }}
                      />
                    </Form.Group>
                  </Form>
                </div>
                <button type="submit" className="btn btn-primary mt-2">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  stakings: state.stakings,
  isLoading: state.isLoading,
  error: state.error,
});

export default connect(mapStateToProps)(ContactsUs);
